import React from "react";
import Frame from "../../Frame";
import Title from "../../Title";
import Button from "../../Button";

const Entreprise = () => {
  return (
    <div className='secondaryBlock'>
      <div className='center'>
        <img
          src={
            "https://cdn.greadn.com/assets/images/logo-greadn-entreprise.svg"
          }
          alt=''
          className='blockLogo'
        />
      </div>
      <Frame type={2} className='center'>
        <Title>Formule Entreprise</Title>
        <p>
          <strong>
            Vous avez une activité d’indépendant, c’est parfait ! C’est une
            formule qui vous permet de gérer votre activité d’indépendant.
          </strong>
        </p>
        <p>
          C’est une formule qui vous permet de gérer votre activité
          d’indépendant.
        </p>
        <p>
          C’est une formule qui vous permet de gérer votre activité
          d’indépendant.
        </p>
        <p className='center'>
          <Button>Contacter un conseiller &gt;</Button>
          <br />
        </p>
      </Frame>
    </div>
  );
};

export default Entreprise;
