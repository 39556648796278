import React from "react";
import Frame from "../../Frame";
import Title from "../../Title";
import Loader from "../../Loader";
import Button from "../../Button";

const StartScreen = ({ handleNextStep }) => {
  return (
    <Frame type={3} className='center'>
      <div className='center'>
        <Loader />
      </div>
      <Title>
        Étape 3<br />
        Tarif Journalier Plafond
      </Title>

      <p>
        De la même manière, le Tarif Journalier Plafond (TJP) est le tarif
        au-dessus duquel vous ne devriez pas monter dans le cadre de vos
        missions.
      </p>
      <p>
        {" "}
        D’autre-part si votre TJM dépasse le TJP, vous risquez fortement de
        compromettre votre volume de missions, de créer un sentiment d’abus au
        sein d’un ou plusieurs clients et d’entamer votre image.
      </p>
      <p>
        <strong>
          Les questions posées dans cette étape concernent vos qualifications
          professionnelles.
        </strong>
      </p>
      <div className='center'>
        <Button
          value='Commencer &gt;'
          onClick={handleNextStep}
          className='center'
        />
      </div>
    </Frame>
  );
};

export default StartScreen;
