import updateData from "./updateData";

import getCurrent from "./getCurrent";

const updateUserMeta = async ({ data, key, value }) => {
  const userData = await getCurrent("user");

  const newData = {
    ...JSON.parse(userData?.meta || "{}"),
  };

  if (Array.isArray(data)) {
    data.forEach((item) => {
      newData[item.key] = item.value;
    });
  } else {
    newData[key] = value;
  }
  console.log(userData);
  return await updateData({
    endpoint: `/users/${userData?.id}`,
    saveIn: "userData",
    body: JSON.stringify({
      meta: JSON.stringify(newData),
    }),
  });
};

export default updateUserMeta;
