import React, { useState, useEffect } from "react";
import updateUserMeta from "../services/updateUserMeta";
import fetchData from "../services/fetchData";

const StepContainer = ({ children, parcoursName, nextRoute }) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (!parcoursName) return;

    fetchData({
      endpoint: "/users",
      saveIn: "userData",
    }).then((response) => {
      if (response?.operation !== "success") return;

      const userMeta =
        response.typeof === "object"
          ? JSON.parse(response?.data.meta)
          : JSON.parse(
              response?.data?.filter((item) => item.id === response.userId)[0]
                .meta
            );

      const currentStep = Object.entries(userMeta).find(
        (key) => key[0] === parcoursName
      );

      currentStep && setStep(currentStep[1] || 0);
    });
  }, [parcoursName]);

  const handleNextStep = () => {
    setStep((prevStep) => {
      const newStep = prevStep + 1;
      parcoursName && updateUserMeta({ key: parcoursName, value: newStep });
      return newStep;
    });
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => {
      if (prevStep === 0) return 0;
      const newStep = prevStep - 1;
      parcoursName && updateUserMeta({ key: parcoursName, value: newStep });
      return newStep;
    });
  };

  const handleSetStep = (newStep) => {
    setStep(newStep);
    parcoursName && updateUserMeta({ key: parcoursName, value: newStep });
  };

  useEffect(() => {
    if (nextRoute && step === children.length - 1) {
      window.location.href = nextRoute;
    }
  }, [step, children.length, nextRoute]);

  return (
    <div>
      {React.Children.map(children, (child, index) => {
        const childProps = child.props;
        const displayButton =
          index !== children.length - 1 ? childProps.displayButton : false;
        const props = {
          handleNextStep,
          handlePreviousStep,
          handleSetStep,
          displayButton,
        };
        if (index === step) {
          return React.cloneElement(child, { ...childProps, ...props });
        }
        return null;
      })}
    </div>
  );
};

export default StepContainer;
