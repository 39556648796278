import React, { useEffect } from "react";

import Frame from "../../Frame";
import Loader from "../../Loader";
import updateUserMeta from "../../../services/updateUserMeta";

const FakeWait = () => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      updateUserMeta({
        key: "step4",
        value: 7,
      }).then(() => {
        window.location.reload();
      });
    }, 5000); // 1 second

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Frame type={3} className='center'>
      <div className='center'>
        <br />
        <Loader animate={true} />
      </div>
      <p className='center info'>
        Greadn détermine la stratégie qui vous conviendrait le mieux en fonction
        de votre profil ...
      </p>
    </Frame>
  );
};

export default FakeWait;
