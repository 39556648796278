import React from "react";
import BusinessDetails from "../components/steps/step0/BusinessDetails";
import SetCompany from "../components/steps/step0/SetCompany";
import FetchSiren from "../components/steps/step0/FetchSiren";
import LoadScreen from "../components/steps/step0/LoadingScreen";
import SetAdrInitial from "../components/steps/step0/SetAdrInitial";
import Start from "../components/steps/step0/Start";
import Question1 from "../components/steps/step0/Question1";
import Result from "../components/steps/step0/Result";
import LayoutPrimary from "../components/partials/LayoutPrimary";
import StepContainer from "../components/StepContainer";
import Step from "../components/Step";
import fetchData from "../services/fetchData";
import Question2 from "../components/steps/step0/Question2";
import FinishInit from "../components/steps/step0/FinishInit";
import FirstBadge from "../components/steps/step0/FirstBadge";
import updateUserMeta from "../services/updateUserMeta";
import updateData from "../services/updateData";

const Step0 = () => {
  return (
    <LayoutPrimary>
      <StepContainer parcoursName='step0' nextRoute='/step1'>
        <Step displayButton={false}>
          <LoadScreen />
        </Step>
        <Step displayButton={false}>
          <Start />
        </Step>
        <Step
          beforeNextStep={async () => {
            return updateUserMeta({
              key: "freelance_since",
              value: JSON.parse(localStorage.getItem("form_businessData"))
                .business_since,
            });
          }}
        >
          <SetCompany />
        </Step>
        <Step displayButton={false}>
          <FetchSiren />
        </Step>
        <Step
          beforeNextStep={async () => {
            return Promise.all([
              updateUserMeta({
                data: [
                  {
                    key: "birthdate",
                    value: JSON.parse(localStorage.getItem("form_businessData"))
                      .birthdate,
                  },
                  {
                    key: "gender",
                    value: JSON.parse(localStorage.getItem("form_businessData"))
                      .gender,
                  },
                ],
              }),
              fetchData({
                endpoint: "/business",
                method: "POST",
                saveInNotRead: "businessData",
                body: JSON.stringify({
                  ...JSON.parse(localStorage.getItem("form_businessData")),
                  company_id: JSON.parse(localStorage.getItem("companyData"))
                    .data.id,
                  user_id: JSON.parse(localStorage.getItem("userData")).userId,
                }),
              }),
            ]);
          }}
        >
          <BusinessDetails />
        </Step>
        <Step
          beforeNextStep={async () => {
            return updateData({
              endpoint: `/business/${
                JSON.parse(localStorage.getItem("businessData"))?.data.id
              }`,
              saveIn: "businessData",
              body: JSON.stringify({
                wish_min_salary: localStorage.getItem("adr_initial"),
              }),
            });
          }}
        >
          <SetAdrInitial />
        </Step>
        <Step
          beforeNextStep={async () => {
            return Promise.all(
              Object.entries(
                JSON.parse(localStorage.getItem("form_questionInit"))
              ).map(([key, value]) =>
                fetchData({
                  endpoint: "/users_answers",
                  method: "POST",
                  body: JSON.stringify({
                    question_id: parseInt(key),
                    answer_id: value,
                    user_id: JSON.parse(localStorage.getItem("userData"))
                      .userId,
                  }),
                })
              )
            );
          }}
        >
          <Question1 />
        </Step>
        <Step
          beforeNextStep={async () => {
            return (
              localStorage.getItem("form_questionAdr") !== null &&
              fetchData({
                endpoint: "/users_answers",
                method: "POST",
                body: JSON.stringify({
                  question_id: 7,
                  answer_id: localStorage.getItem("form_questionAdr"),
                  user_id: JSON.parse(localStorage.getItem("userData")).userId,
                }),
              })
            );
          }}
        >
          <Question2 />
        </Step>
        <Step>
          <FinishInit />
        </Step>
        <Step>
          <Result />
        </Step>
        <Step>
          <FirstBadge />
        </Step>
        <Step>{/* Next Parcours */}</Step>
      </StepContainer>
    </LayoutPrimary>
  );
};

export default Step0;
