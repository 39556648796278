import Frame from "../../Frame";
import Question from "../../Question";
import CalendarMonth from "../../CalendarMonth";
import InputSiret from "../../InputSiret";
import Label from "../../Label";

const SetCompany = ({ handleNextStep }) => {
  const userDataLS = JSON.parse(localStorage.getItem("userData"));
  const userData =
    userDataLS.typeof === "object"
      ? userDataLS?.data
      : userDataLS?.data?.filter((item) => item.id === userDataLS.userId)[0];

  if (userData?.type === "freelance-lancer") {
    handleNextStep();
  }
  return (
    <Frame type={3} className='center'>
      <Question>Depuis quand êtes-vous Freelance ?</Question>
      <CalendarMonth saveIn='form_businessData' name='business_since' />
      <Question>Quel est votre SIRET ?</Question>
      <p>
        En cas de portage salarial, indiquer le SIRET de votre entreprise de
        portage.
      </p>

      <Label>Numéro de SIRET</Label>
      <InputSiret
        name='siretCompany'
        value={localStorage.getItem("siretCompany")}
      />
      <p>
        <br />
      </p>
    </Frame>
  );
};

export default SetCompany;
