import LayoutPrimary from "../components/partials/LayoutPrimary";
import Title from "../components/Title";

const HomePage = () => {
  return (
    <LayoutPrimary>
      <div>
        <Title>Effacer vos données</Title>
        <p>
          Greadn ne transmet aucune donnée à des tiers. Supprimer vos données
          engendrera l’impossibilité pour Greadn de les utiliser de manière
          anonymisée pour améliorer son service. Vous ne pourrez alors plus
          accéder à vos résultats et toutes les informations saisies devront
          l’être à nouveau si vous choisissez de refaire un parcours.
        </p>
        <p>
          Si toutefois vous souhaitez quand-même supprimer vos données, 2 choix
          s’offrent à vous :
        </p>
        <ul>
          <li>
            Supprimer votre compte et toutes les données associées. Pour vous
            reconnecter à Greadn il faudra alors créer un nouveau compte.
          </li>
          <li>
            Supprimer uniquement les données de votre compte pour le
            réinitialiser
          </li>
        </ul>
      </div>
    </LayoutPrimary>
  );
};

export default HomePage;
