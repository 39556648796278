import React, { useEffect, useState } from "react";
import LayoutPrimary from "../components/partials/LayoutPrimary";
import StepContainer from "../components/StepContainer";
import Step from "../components/Step";
import Title from "../components/Title";
import Loader from "../components/Loader";
import Frame from "../components/Frame";
import StartScreen from "../components/steps/step2/StartScreen";
import TimeRate from "../components/steps/step2/TimeRate";
import Diploma from "../components/steps/step2/Diploma";
import Langues from "../components/steps/step2/Langues";
import FinishBadge from "../components/steps/step2/FinishBadge";
import SinceFreelance from "../components/steps/step2/SinceFreelance";
import Offers from "../components/steps/step2/Offers";
import Request from "../components/steps/step2/Request";
import FinishProfil from "../components/steps/step2/FinishProfil";
import fetchData from "../services/fetchData";
import updateUserMeta from "../services/updateUserMeta";

const Step2 = () => {
  const [business, setBusiness] = useState({});

  useEffect(() => {
    fetchData({
      endpoint: `/business`,
      saveIn: "businessData",
    }).then((response) => {
      if (response?.operation === "success") {
        setBusiness(response);
      }
    });
  }, []);

  return (
    <LayoutPrimary>
      <StepContainer parcoursName='step2' nextRoute='/step3'>
        <Step>
          <Frame type={3} className='center'>
            <div className='center'>
              <Loader animate={true} />
            </div>

            <Title>
              Étape 2<br />
              Tarif Journalier Moyen du marché
            </Title>
            <p>
              Grâce à cette étape, Greadn vous fournira la fourchette de Tarifs
              Journaliers Moyens pratiqués par les Freelances aux profils
              proches du votre.
            </p>
          </Frame>
        </Step>
        <Step displayButton={false}>
          <StartScreen />
        </Step>
        <Step displayButton='both'>
          <SinceFreelance />
        </Step>
        <Step displayButton='both'>
          <TimeRate />
        </Step>
        <Step displayButton='both'>
          <Diploma />
        </Step>
        <Step displayButton='both'>
          <Langues />
        </Step>
        <Step displayButton='both'>
          <Offers />
        </Step>
        <Step
          displayButton='both'
          beforeNextStep={async () => {
            const form = JSON.parse(localStorage.getItem("form_step2") || "[]");
            const userData = JSON.parse(
              localStorage.getItem("userData") || "[]"
            );

            const businessPromise = fetchData({
              endpoint: `/business/${business.id}`,
              method: "PUT",
              saveIn: "businessData",
              data: JSON.stringify({
                remote: form.remote,
                turnover: form.turnover,
                diploma_id: form.jobDiploma,
                missions_achieved: form.nbMissions,
                business_since: form.job_since,
              }),
            });

            const userPromise = fetchData({
              endpoint: `/users${
                userData.userLevel === "admin" ? `/${userData.userId}` : ""
              }`,
              method: "PUT",
              saveIn: "userData",
              data: JSON.stringify({
                work_since: form.work_since,
                diploma_id: form.maxDiploma,
              }),
            });

            const languesPromise = updateUserMeta({
              key: "langues",
              value: JSON.stringify({
                french: form.francais,
                anglais: form.anglais,
              }),
            });

            await businessPromise;
            await userPromise;
            await languesPromise;
          }}
        >
          <Request />
        </Step>
        <Step displayButton={false}>
          <FinishProfil />
        </Step>
        <Step>
          <FinishBadge />
        </Step>
        <Step>{/* Next Parcours */}</Step>
      </StepContainer>
    </LayoutPrimary>
  );
};

export default Step2;
