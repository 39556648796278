import React, { useState, useEffect } from "react";

const scale = (number) => {
  if (number < 10) {
    return "Pas du tout";
  } else if (number < 20) {
    return "Très peu";
  } else if (number < 30) {
    return "Un peu";
  } else if (number < 40) {
    return "Assez";
  } else if (number < 50) {
    return "Moyennement";
  } else if (number < 60) {
    return "Bien";
  } else if (number < 70) {
    return "Très bien";
  } else if (number <= 80) {
    return "Parfaitement";
  } else {
    return "";
  }
};

const RangeCommunication = ({
  min = 0,
  max = 80,
  step = 1,
  value = 60,
  label,
  onChange,
  name = null,
  saveIn = null,
}) => {
  const [rangeValue, setRangeValue] = useState(() => {
    try {
      const prevData = JSON.parse(localStorage.getItem(saveIn) || "{}");
      if (prevData && (prevData[name] || prevData[name] === 0)) {
        return prevData[name] > max ? max : prevData[name];
      }
    } catch (error) {
      console.error(error);
    }
    return value > max ? max : value;
  });
  const [rangeValueScale, setRangeValueScale] = useState(scale(value));
  const [leftValue, setLeftValue] = useState(
    ((rangeValue - min) / (max - min)) * 100
  );

  useEffect(() => {
    if (saveIn && name) {
      let prevData = JSON.parse(localStorage.getItem(saveIn) || "[]");
      if (typeof prevData !== "object") {
        prevData = [];
      }

      localStorage.setItem(
        saveIn,
        JSON.stringify({
          ...prevData,
          [name]: rangeValue,
        })
      );
    }
  }, [saveIn, name, rangeValue]);

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value);
    setRangeValue(newValue);
    setLeftValue(((newValue - min) / (max - min)) * 100);
    setRangeValueScale(scale(parseInt(e.target.value)));

    onChange && onChange(newValue);
  };

  return (
    <div className='secondaryBlock rangeRateComponent '>
      <div
        className='labelContainer'
        style={{
          justifyContent: "center",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
        }}
      >
        {label && (
          <p className='secondaryFont'>
            <strong>{label}</strong>
          </p>
        )}
        <div className='secondaryBlock' style={{ padding: `7px 18px 5px` }}>
          <p className='secondaryFont'>{rangeValueScale}</p>
        </div>
      </div>

      <div className='rangeComponent rangeGreen'>
        <input
          type='range'
          min={min}
          max={max}
          step={step}
          value={rangeValue}
          onChange={handleChange}
          className='range-input'
        />

        <div className='range-track' style={{ width: `${leftValue}%` }}></div>

        <div className='bg'></div>
      </div>
    </div>
  );
};

export default RangeCommunication;
