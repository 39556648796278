import React from "react";
import Frame from "../../Frame";
import Title from "../../Title";
import StepContainer from "../../StepContainer";
import Step from "../../Step";
import BigButton from "../../BigButton";

const Freelance = () => {
  return (
    <div className='secondaryBlock'>
      <div className='center'>
        <img
          src={"https://cdn.greadn.com/assets/images/logo-greadn-freelance.svg"}
          alt=''
          className='blockLogo'
        />
      </div>
      <StepContainer parcoursName='freelance-option'>
        <Step>
          <Frame type={2} className='center'>
            <Title>La formule Freelance</Title>
            <p>
              <strong>
                Le parcours Freelance est libre jusqu’à obtention de vos
                résultats
              </strong>
            </p>
            <p>Profitez-en ! C’est une mine d’informations.</p>{" "}
            <p>Vos résultats sont payants. Enfin... normalement.</p>
            <p>
              Car, lorsque votre TJM actuel est égal au TJM Greadn conseillé,
              c’est 0€ !
            </p>
          </Frame>
        </Step>
        <Step>
          <Frame type={2} className='center'>
            <Title>Un tarif dynamique</Title>
            <p>
              <strong>Greadn fournit un tarif juste.</strong>
            </p>

            <p>
              <strong>Son propre tarif doit l’être aussi.</strong>
            </p>

            <p>
              Greadn innove donc, avec un tarif dynamique :<br />
              le prix de vos résultats varie selon leur impact estimé sur votre
              activité.
            </p>

            <p>
              Notamment :<br></br>
              Si dans un parcours, votre TJM actuel est égal au TJM conseillé
              par Greadn, tous les résultats liés à ce parcours sont offerts.{" "}
            </p>

            <p>
              Cerise sur le verger, le tarif Greadnest plafonné pour permettre à
              tous d’accéder à son service.
            </p>

            <p>Si le TJM conseillé Greadn est &lt; 190€, c’est 29€ maximum.</p>
            <p>Si le TJM conseillé Greadn est &lt; 300€, c’est 49€ maximum.</p>
            <p>Et ainsi de suite.</p>
            <BigButton
              content='Démarrer'
              onClick={() => {
                window.location.href = "/step0";
              }}
            />
          </Frame>
        </Step>
      </StepContainer>
      <p>
        <br />
      </p>
    </div>
  );
};

export default Freelance;
