const updateData = async ({
  endpoint = null,
  method = "PUT",
  body = null,
  saveIn = null,
}) => {
  if (endpoint == null) {
    throw new Error("endpoint is required");
  }

  const headers = {
    "Content-Type": "application/json",
  };

  if (
    localStorage.getItem("userToken") !== null &&
    localStorage.getItem("userToken") !== ""
  ) {
    headers["Authorization"] = `Bearer ${localStorage.getItem("userToken")}`;
  }

  localStorage.setItem("headers", JSON.stringify(headers));

  const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
    method,
    body,
    headers,
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));

  if (saveIn && response?.operation === "success") {
    localStorage.setItem(saveIn, JSON.stringify(response));
  }

  return response;
};

export default updateData;
