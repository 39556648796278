import React from "react";
import LayoutPrimary from "../components/partials/LayoutPrimary";
import StepContainer from "../components/StepContainer";
import Step from "../components/Step";
import Title from "../components/Title";
import Loader from "../components/Loader";
import Frame from "../components/Frame";
import StartScreen from "../components/steps/step4/StartScreen";
import GeneralAnswers from "../components/steps/step4/GeneralAnswers";
import GeneralAnswers2 from "../components/steps/step4/GeneralAnswers2";
import GeneralAnswers3 from "../components/steps/step4/GeneralAnswers3";
import FinishBadge from "../components/steps/step4/FinishBadge";
import GeneralAnswers4 from "../components/steps/step4/GeneralAnswers4";
import FakeWait from "../components/steps/step4/FakeWait";
import FinishProfil from "../components/steps/step4/FinishProfil";
import AdviceStrat from "../components/steps/step4/AdviceStrat";

const Step4 = () => {
  return (
    <LayoutPrimary>
      <StepContainer parcoursName='step4' nextRoute='/end'>
        <Step>
          <Frame type={3} className='center'>
            <div className='center'>
              <Loader animate={true} />
            </div>

            <Title>
              Étape 4<br />
              Stratégie de TJM
            </Title>
            <p>
              Le TJM n’a de sens que s’il est cohérent avec votre stratégie
              tarifaire. En matière de tarif, Il n’existe pas de bonne ou
              mauvaise stratégie. La meilleure est celle qui vous correspond !
            </p>
          </Frame>
        </Step>
        <Step displayButton={false}>
          <StartScreen />
        </Step>
        <Step displayButton='both'>
          <GeneralAnswers />
        </Step>
        <Step displayButton='both'>
          <GeneralAnswers2 />
        </Step>
        <Step displayButton='both'>
          <GeneralAnswers3 />
        </Step>
        <Step displayButton='both'>
          <GeneralAnswers4 />
        </Step>
        <Step displayButton={false}>
          <FakeWait />
        </Step>
        <Step>
          <AdviceStrat />
        </Step>
        <Step displayButton={false}>
          <FinishProfil />
        </Step>
        <Step>
          <FinishBadge />
        </Step>
        <Step>{/* Next Parcours */}</Step>
      </StepContainer>
    </LayoutPrimary>
  );
};

export default Step4;
