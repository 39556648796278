const Jauge = ({ min, max, minMarket, maxMarket, choice, advice }) => {
  return (
    <div className='jaugeComponent'>
      <svg
        width='292'
        height='292'
        viewBox='0 0 292 292'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_3878_18128)'>
          <path
            opacity='0.06'
            d='M290.031 145.993C290.031 66.4459 225.554 1.96875 146.007 1.96875C66.4603 1.96875 1.96875 66.4459 1.96875 145.993C1.96875 225.54 66.4603 290.031 146.007 290.031C225.554 290.031 290.046 225.54 290.046 145.993H290.031Z'
            fill='url(#paint0_linear_3878_18128)'
          />
          <path
            opacity='0.12'
            d='M288.077 145.993C288.077 67.5314 224.468 3.92285 146.007 3.92285C67.5461 3.92285 3.9231 67.5314 3.9231 145.993C3.9231 224.454 67.5317 288.062 145.993 288.062C224.454 288.062 288.063 224.454 288.063 145.993H288.077Z'
            fill='url(#paint1_linear_3878_18128)'
          />
          <path
            opacity='0.19'
            d='M286.108 145.993C286.108 68.6171 223.383 5.8916 146.007 5.8916C68.6318 5.8916 5.89185 68.6171 5.89185 145.993C5.89185 223.368 68.6173 286.108 146.007 286.108C223.397 286.108 286.108 223.382 286.108 145.993Z'
            fill='url(#paint2_linear_3878_18128)'
          />
          <path
            opacity='0.25'
            d='M284.139 145.992C284.139 69.7026 222.297 7.8457 145.993 7.8457C69.6886 7.8457 7.8606 69.7026 7.8606 145.992C7.8606 222.282 69.703 284.139 146.007 284.139C222.311 284.139 284.154 222.297 284.154 145.992H284.139Z'
            fill='url(#paint3_linear_3878_18128)'
          />
          <path
            opacity='0.31'
            d='M282.185 145.992C282.185 70.7883 221.211 9.81445 146.007 9.81445C70.8033 9.81445 9.81494 70.7883 9.81494 145.992C9.81494 221.196 70.7888 282.17 145.993 282.17C221.197 282.17 282.171 221.196 282.171 145.992H282.185Z'
            fill='url(#paint4_linear_3878_18128)'
          />
          <path
            opacity='0.38'
            d='M280.216 145.992C280.216 71.874 220.126 11.7832 146.007 11.7832C71.889 11.7832 11.7837 71.874 11.7837 145.992C11.7837 220.111 71.8745 280.216 146.007 280.216C220.14 280.216 280.216 220.125 280.216 145.992Z'
            fill='url(#paint5_linear_3878_18128)'
          />
          <path
            opacity='0.44'
            d='M278.248 145.993C278.248 72.9605 219.04 13.7383 145.993 13.7383C72.9457 13.7383 13.7524 72.9605 13.7524 145.993C13.7524 219.026 72.9602 278.248 146.007 278.248C219.054 278.248 278.262 219.04 278.262 145.993H278.248Z'
            fill='url(#paint6_linear_3878_18128)'
          />
          <path
            opacity='0.5'
            d='M276.293 145.993C276.293 74.0462 217.969 15.707 146.007 15.707C74.046 15.707 15.7068 74.0462 15.7068 145.993C15.7068 217.94 74.0315 276.279 145.993 276.279C217.954 276.279 276.279 217.954 276.279 145.993H276.293Z'
            fill='url(#paint7_linear_3878_18128)'
          />
          <path
            opacity='0.56'
            d='M274.325 145.993C274.325 75.1175 216.868 17.6758 146.007 17.6758C75.1462 17.6758 17.6755 75.1319 17.6755 145.993C17.6755 216.854 75.1317 274.325 146.007 274.325C216.883 274.325 274.325 216.869 274.325 145.993Z'
            fill='url(#paint8_linear_3878_18128)'
          />
          <path
            opacity='0.62'
            d='M272.356 145.993C272.356 76.203 215.783 19.6299 146.007 19.6299C76.2319 19.6299 19.6443 76.2175 19.6443 145.993C19.6443 215.768 76.2174 272.356 146.007 272.356C215.797 272.356 272.356 215.783 272.356 145.993Z'
            fill='url(#paint9_linear_3878_18128)'
          />
          <path
            opacity='0.69'
            d='M270.387 145.993C270.387 77.2887 214.697 21.5986 145.993 21.5986C77.2887 21.5986 21.5986 77.3032 21.5986 145.993C21.5986 214.683 77.2887 270.387 145.993 270.387C214.697 270.387 270.387 214.697 270.387 145.993Z'
            fill='url(#paint10_linear_3878_18128)'
          />
          <path
            opacity='0.75'
            d='M268.433 145.993C268.433 78.3744 213.611 23.5674 146.007 23.5674C78.4033 23.5674 23.5674 78.3889 23.5674 145.993C23.5674 213.597 78.3889 268.418 146.007 268.418C213.626 268.418 268.433 213.597 268.433 145.993Z'
            fill='url(#paint11_linear_3878_18128)'
          />
          <path
            opacity='0.81'
            d='M266.464 145.993C266.464 79.4599 212.526 25.5215 146.007 25.5215C79.489 25.5215 25.5361 79.4744 25.5361 145.993C25.5361 212.511 79.4746 266.464 146.007 266.464C212.54 266.464 266.464 212.525 266.464 145.993Z'
            fill='url(#paint12_linear_3878_18128)'
          />
          <path
            opacity='0.88'
            d='M264.495 145.993C264.495 80.5456 211.44 27.4902 145.993 27.4902C80.5458 27.4902 27.5049 80.5456 27.5049 145.993C27.5049 211.44 80.5603 264.495 146.007 264.495C211.454 264.495 264.51 211.44 264.51 145.993H264.495Z'
            fill='url(#paint13_linear_3878_18128)'
          />
          <path
            opacity='0.94'
            d='M262.541 145.993C262.541 81.6313 210.369 29.459 146.007 29.459C81.646 29.459 29.4592 81.6313 29.4592 145.993C29.4592 210.354 81.6316 262.526 145.993 262.526C210.354 262.526 262.526 210.354 262.526 145.993H262.541Z'
            fill='url(#paint14_linear_3878_18128)'
          />
          <path
            d='M260.572 145.992C260.572 82.7168 209.268 31.4131 146.007 31.4131C82.7462 31.4131 31.428 82.7168 31.428 145.992C31.428 209.268 82.7317 260.572 146.007 260.572C209.283 260.572 260.572 209.268 260.572 145.992Z'
            fill='url(#paint15_linear_3878_18128)'
          />
          <path
            d='M252.074 193.591C278.349 135.006 252.176 66.2147 193.59 39.9403C135.02 13.666 66.2142 39.839 39.9398 98.4243C13.6655 157.01 39.853 225.801 98.4238 252.075C156.995 278.349 225.8 252.176 252.074 193.591Z'
            fill='url(#paint16_radial_3878_18128)'
          />
          <circle
            cx='146.751'
            cy='146'
            r='113.974'
            stroke='#C9D6ED'
            stroke-width='4'
          />
          <g clipPath='url(#clip1_3878_18128)'>
            <path
              d='M58.438 124.821C60.8195 132.53 68.9954 136.84 76.6803 134.451C79.8721 133.453 82.4501 131.459 84.2301 128.922C84.4143 128.663 84.5984 128.405 84.758 128.122C85.0281 127.678 85.2736 127.235 85.4946 126.767H85.5191C87.0782 123.812 88.8214 121.016 90.7365 118.394C90.9083 118.16 91.0802 117.926 91.2521 117.692C91.4485 117.421 91.6694 117.162 91.8659 116.891C92.2219 116.423 92.6024 115.968 92.9707 115.512C93.3267 115.069 93.695 114.638 94.051 114.207C94.407 113.801 94.7507 113.382 95.1068 112.988C95.8188 112.2 96.5431 111.436 97.2919 110.685C97.5865 110.39 97.8689 110.106 98.1635 109.823C98.851 109.158 99.5384 108.506 100.25 107.878C100.594 107.57 100.938 107.274 101.282 106.979C101.92 106.437 102.571 105.895 103.221 105.378C103.675 105.008 104.142 104.651 104.608 104.294C104.866 104.097 105.136 103.912 105.394 103.715C111.348 99.381 117.94 96.0686 124.925 93.889C124.987 93.8644 125.048 93.8521 125.11 93.8398V93.8151C117.474 96.0686 109.433 91.771 107.064 84.1364C104.768 76.7235 108.66 68.8796 115.804 66.1582C92.5779 73.5219 72.0031 89.8131 59.7515 113.173C59.3955 113.801 59.1009 114.453 58.8308 115.118C58.7694 115.266 58.7326 115.414 58.6712 115.561C57.6277 118.443 57.4559 121.669 58.4257 124.821H58.438Z'
              fill='url(#paint17_linear_3878_18128)'
            />
            <path
              d='M107.088 84.1367C109.457 91.7836 117.498 96.0688 125.122 93.8153V93.84C125.183 93.8153 125.245 93.803 125.306 93.7784C141.731 88.6805 159.937 89.8257 176.362 98.4823C176.362 98.4823 176.313 98.4454 176.289 98.433C172.765 96.7337 169.917 93.6429 168.678 89.604C166.296 81.8955 170.593 73.6945 178.277 71.3056C181.727 70.2343 185.275 70.5175 188.356 71.8474C165.719 60.4202 140.516 58.807 117.903 65.5304C117.498 65.6166 117.093 65.7151 116.688 65.8383C116.394 65.9245 116.111 66.0476 115.829 66.1584C108.696 68.8798 104.793 76.7237 107.088 84.1367Z'
              fill='url(#paint18_linear_3878_18128)'
            />
            <path
              d='M178.277 71.3177C170.593 73.7065 166.296 81.9076 168.677 89.616C169.93 93.655 172.765 96.7458 176.289 98.4451C176.313 98.4574 176.338 98.4697 176.362 98.482C178.511 99.6149 180.585 100.871 182.586 102.238C191.622 108.358 199.208 116.682 204.438 126.767C207.691 133.047 214.934 136.125 221.674 134.02C230.365 131.311 234.478 121.361 230.267 113.259C221.023 95.4528 206.635 81.3781 189.645 72.4752C189.228 72.2535 188.786 72.0442 188.344 71.8472C185.263 70.5173 181.727 70.234 178.265 71.3053L178.277 71.3177Z'
              fill='url(#paint19_linear_3878_18128)'
            />
          </g>
          <g filter='url(#filter0_d_3878_18128)'>
            <path
              d='M91.9515 140.493C96.7856 129.714 91.9702 117.058 81.1914 112.224C70.4153 107.39 57.7562 112.205 52.9221 122.984C48.088 133.762 52.9061 146.419 63.6822 151.253C74.4583 156.087 87.1175 151.271 91.9515 140.493Z'
              fill='url(#paint20_radial_3878_18128)'
            />
          </g>
          <circle
            cx='72.437'
            cy='131.738'
            r='20.3933'
            stroke='#C9D6ED'
            stroke-width='2'
          />
          <g filter='url(#filter1_d_3878_18128)'>
            <path
              d='M239.077 140.493C243.912 129.714 239.096 117.058 228.317 112.224C217.541 107.39 204.882 112.205 200.048 122.984C195.214 133.762 200.032 146.419 210.808 151.253C221.584 156.087 234.243 151.271 239.077 140.493Z'
              fill='url(#paint21_radial_3878_18128)'
            />
          </g>
          <circle
            cx='219.563'
            cy='131.738'
            r='20.3933'
            stroke='#C9D6ED'
            stroke-width='2'
          />
          <text
            fill='#07084C'
            font-family='Poppins'
            font-size='10'
            font-weight='600'
            letter-spacing='-0.04em'
          >
            <tspan x='207.928' y='135.86'>
              {max ? max + "€" : "Max"}
            </tspan>
          </text>
          <text
            fill='#07084C'
            font-family='Poppins'
            font-size='10'
            font-weight='600'
            letter-spacing='-0.04em'
          >
            <tspan x='60.0515' y='134.86'>
              {min ? min + "€" : "Mini"}
            </tspan>
          </text>
          <g clipPath='url(#clip2_3878_18128)'>
            <path
              d='M69.3651 78.9455C79.9261 68.8162 92.0614 61.2645 105.034 56.2151C118.003 51.1687 131.811 48.6396 145.625 48.6402C159.438 48.6402 173.246 51.1693 186.215 56.2157C199.188 61.2651 211.323 68.8162 221.884 78.9461C227.964 84.7794 237.822 84.7794 243.902 78.9461C249.982 73.1128 249.982 63.6551 243.902 57.8218C230.365 44.8312 214.658 35.0419 197.917 28.531C181.173 22.0166 163.395 18.7656 145.625 18.7656C127.853 18.7656 110.076 22.0166 93.3318 28.5304C76.5905 35.0413 60.8836 44.83 47.3465 57.8212C41.2664 63.6545 41.2664 73.1122 47.3465 78.9455C53.4266 84.7788 63.2844 84.7788 69.3645 78.9455H69.3651Z'
              fill='#C9D6ED'
            />
            <text
              fill='#07084C'
              font-family='Poppins'
              font-size='10'
              font-weight='600'
              letter-spacing='-0.04em'
            >
              <tspan x='48.041' y='71.3047'>
                {minMarket ? minMarket + "€" : "Mini"}
              </tspan>
            </text>
            <text
              fill='#07084C'
              font-family='Poppins'
              font-size='10'
              font-weight='600'
              letter-spacing='-0.04em'
            >
              <tspan x='219.188' y='71.3047'>
                {maxMarket ? maxMarket + "€" : "Max"}
              </tspan>
            </text>
            <g clipPath='url(#clip3_3878_18128)'>
              <path
                d='M125.037 29.8945L125.645 40.4548L123.334 40.5865L122.88 32.7263L122.655 32.738L121.082 40.7135L117.763 40.9016L115.289 33.159L115.064 33.1708L115.518 41.031L113.207 41.1627L112.596 30.6025L115.998 30.4096V30.419L116.559 30.3861L119.161 38.7143L119.374 38.7026L121.004 30.1321L121.718 30.0921V30.0827L125.037 29.8945Z'
                fill='#07084C'
              />
              <path
                d='M137.532 40.185L135.136 40.2414L134.466 38.5127L129.033 38.6374L128.446 40.3943L126.05 40.4507L129.607 29.7964L133.482 29.707L137.535 40.185H137.532ZM133.662 36.4336L131.883 31.8332L131.301 31.8473L129.74 36.5253L133.664 36.4336H133.662Z'
                fill='#07084C'
              />
              <path
                d='M145.436 36.1697L147.444 40.2433L144.937 40.2292L143.231 36.6871C143.004 36.6966 142.805 36.6965 142.661 36.6965C142.273 36.6965 140.693 36.6871 140.213 36.6848L140.194 40.208L137.88 40.1962L137.937 29.6172L140.25 29.6289V29.6384C140.816 29.6384 142.498 29.6407 143.127 29.6454C144.404 29.6525 147.246 29.6619 147.227 33.1992C147.217 35.0267 146.399 35.6829 145.438 36.1721L145.436 36.1697ZM145.081 33.1804C145.088 31.7128 143.692 31.7057 142.973 31.701C142.552 31.701 140.847 31.6893 140.239 31.6869L140.222 34.6268C140.724 34.6292 142.72 34.6409 142.921 34.6409C143.581 34.6433 145.072 34.6598 145.081 33.1804Z'
                fill='#07084C'
              />
              <path
                d='M155.977 36.4754L158.38 36.5507C158.094 38.905 155.975 40.502 153.008 40.4079C149.954 40.3115 147.681 38.404 147.794 34.8643C147.906 31.3905 150.3 29.5654 153.351 29.6618C156.318 29.7559 158.39 31.5951 158.466 33.853L156.069 33.7777C155.774 32.63 154.993 32.0632 153.278 32.0091C151.459 31.9526 150.172 32.8393 150.103 34.9396C150.035 37.0422 151.265 38.0065 153.084 38.0653C154.865 38.1218 155.653 37.5103 155.977 36.4754Z'
                fill='#07084C'
              />
              <path
                d='M167.337 30.5157L169.648 30.6545L169.007 41.2147L166.696 41.076L166.953 36.833L161.404 36.4991L161.146 40.742L158.835 40.6032L159.476 30.043L161.787 30.1817L161.529 34.4246L167.079 34.7586L167.337 30.5157Z'
                fill='#07084C'
              />
              <path
                d='M172.815 33.0112L172.626 35.175L179.032 35.7301L178.85 37.8021L172.444 37.2471L172.257 39.385L178.663 39.9401L178.479 42.038L172.073 41.4829L169.769 41.283L170.694 30.7439L179.406 31.4989L179.224 33.571L172.818 33.0159L172.815 33.0112ZM173.343 30.3865L172.813 28.9588L177.057 27.7734L177.641 29.5209L173.341 30.3865H173.343Z'
                fill='#07084C'
              />
            </g>
          </g>

          <g filter='url(#filter2_d_3878_18128)'>
            <path
              d='M144.181 171.571C142.752 172.455 140.91 171.425 140.91 169.741V152.001C140.91 152.001 140.862 142.331 148.761 137.508C154.589 133.946 164.366 128.287 169.455 125.367C170.908 124.53 172.714 125.609 172.677 127.281L172.253 145.76C172.144 150.692 169.806 155.37 165.784 158.229C165.238 158.617 164.645 159.005 164.003 159.393C158.526 162.725 149.137 168.505 144.181 171.559V171.571Z'
              fill='#AEC3E5'
            />
          </g>
          <g clipPath='url(#clip6_3878_18128)'>
            <g filter='url(#filter3_d_3878_18128)'>
              <path
                d='M144.483 170.892C144.031 172.511 141.978 173 140.837 171.761L128.822 158.709C128.822 158.709 122.237 151.628 124.782 142.73C126.657 136.162 130.017 125.376 131.783 119.781C132.286 118.181 134.345 117.752 135.451 119.007L147.655 132.89C150.915 136.592 152.363 141.617 151.34 146.445C151.202 147.1 151.028 147.787 150.818 148.507C149.046 154.668 146.053 165.28 144.475 170.883L144.483 170.892Z'
                fill='#D6E3F4'
              />
            </g>
          </g>
          <path
            d='M136.617 168.144L136.617 199.671'
            stroke='#ACC0E2'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-dasharray='2 2'
          />
          <path
            d='M152.38 165.142L152.38 204.926'
            stroke='#AABFE0'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-dasharray='2 2'
          />
          <text
            transform={advice ? "translate(158 180)" : "translate(158 180)"}
            fill={advice ? "#FC7315" : "#AEC3E5"}
            font-family='Poppins'
            font-size='11'
            font-weight='600'
            letter-spacing='-0.04em'
          >
            <tspan x='0' y='24.85'>
              {advice ? advice + "€" : "À définir"}
            </tspan>
          </text>
          <text
            transform='translate(158 180)'
            fill='#07084C'
            font-family='Poppins'
            font-size='11'
            font-weight='600'
            letter-spacing='-0.04em'
          >
            <tspan x='0' y='10.85'>
              TJM conseill&#xe9;&#x2028;
            </tspan>
          </text>
          <text
            transform={choice ? "translate(70 176)" : "translate(50 176)"}
            fill={choice ? "#40B59E" : "#AEC3E5"}
            font-family='Poppins'
            font-size='12'
            font-weight='600'
            letter-spacing='-0.04em'
          >
            <tspan x='32.6171' y='25.2'>
              {choice ? choice + "€" : "À définir"}
            </tspan>
          </text>
          <text
            transform='translate(70 176)'
            fill='#07084C'
            font-family='Poppins'
            font-size='12'
            font-weight='600'
            letter-spacing='-0.04em'
          >
            <tspan x='1.67674' y='11.2'>
              TJM choisi&#x2028;
            </tspan>
          </text>
        </g>
        <defs>
          <filter
            id='filter0_d_3878_18128'
            x='38.1437'
            y='99.4447'
            width='72.5866'
            height='72.5871'
            filterUnits='userSpaceOnUse'
            color-interpolation-filters='sRGB'
          >
            <feFlood flood-opacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dx='2' dy='4' />
            <feGaussianBlur stdDeviation='7.45' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_3878_18128'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_3878_18128'
              result='shape'
            />
          </filter>
          <filter
            id='filter1_d_3878_18128'
            x='185.27'
            y='99.4447'
            width='72.5866'
            height='72.5871'
            filterUnits='userSpaceOnUse'
            color-interpolation-filters='sRGB'
          >
            <feFlood flood-opacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dx='2' dy='4' />
            <feGaussianBlur stdDeviation='7.45' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_3878_18128'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_3878_18128'
              result='shape'
            />
          </filter>
          <filter
            id='filter2_d_3878_18128'
            x='137.91'
            y='122.077'
            width='51.7678'
            height='66.8174'
            filterUnits='userSpaceOnUse'
            color-interpolation-filters='sRGB'
          >
            <feFlood flood-opacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dx='7' dy='7' />
            <feGaussianBlur stdDeviation='5' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_3878_18128'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_3878_18128'
              result='shape'
            />
          </filter>
          <filter
            id='filter3_d_3878_18128'
            x='121.197'
            y='115.276'
            width='47.4663'
            height='74.1816'
            filterUnits='userSpaceOnUse'
            color-interpolation-filters='sRGB'
          >
            <feFlood flood-opacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dx='7' dy='7' />
            <feGaussianBlur stdDeviation='5' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_3878_18128'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_3878_18128'
              result='shape'
            />
          </filter>
          <linearGradient
            id='paint0_linear_3878_18128'
            x1='2.89523'
            y1='138.972'
            x2='289.192'
            y2='153.028'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.32' stop-color='#FBFBFD' />
            <stop offset='0.62' stop-color='#EFF2F9' />
            <stop offset='0.91' stop-color='#DCE3F2' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_3878_18128'
            x1='6.10901'
            y1='132.226'
            x2='286.065'
            y2='159.788'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.32' stop-color='#FBFBFD' />
            <stop offset='0.62' stop-color='#EFF2F9' />
            <stop offset='0.91' stop-color='#DCE3F1' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <linearGradient
            id='paint2_linear_3878_18128'
            x1='9.59776'
            y1='125.769'
            x2='282.648'
            y2='166.274'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.31' stop-color='#FBFBFD' />
            <stop offset='0.62' stop-color='#EFF2F9' />
            <stop offset='0.91' stop-color='#DCE3F1' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <linearGradient
            id='paint3_linear_3878_18128'
            x1='13.376'
            y1='119.617'
            x2='278.971'
            y2='172.44'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.31' stop-color='#FBFBFD' />
            <stop offset='0.62' stop-color='#EFF2F9' />
            <stop offset='0.91' stop-color='#DCE2F1' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <linearGradient
            id='paint4_linear_3878_18128'
            x1='17.386'
            y1='113.783'
            x2='275.019'
            y2='178.318'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.31' stop-color='#FBFBFD' />
            <stop offset='0.62' stop-color='#EFF2F9' />
            <stop offset='0.91' stop-color='#DCE2F1' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <linearGradient
            id='paint5_linear_3878_18128'
            x1='21.642'
            y1='108.267'
            x2='270.85'
            y2='183.862'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.31' stop-color='#FBFBFD' />
            <stop offset='0.62' stop-color='#EFF2F9' />
            <stop offset='0.92' stop-color='#DBE2F1' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <linearGradient
            id='paint6_linear_3878_18128'
            x1='26.0862'
            y1='103.1'
            x2='266.449'
            y2='189.103'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.31' stop-color='#FBFBFD' />
            <stop offset='0.62' stop-color='#EFF2F9' />
            <stop offset='0.92' stop-color='#DBE2F1' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <linearGradient
            id='paint7_linear_3878_18128'
            x1='30.7331'
            y1='98.2505'
            x2='261.875'
            y2='193.996'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.31' stop-color='#FBFBFD' />
            <stop offset='0.62' stop-color='#EFF2F8' />
            <stop offset='0.92' stop-color='#DBE2F1' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <linearGradient
            id='paint8_linear_3878_18128'
            x1='35.5247'
            y1='93.7484'
            x2='257.112'
            y2='198.556'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.31' stop-color='#FBFBFD' />
            <stop offset='0.62' stop-color='#EFF2F8' />
            <stop offset='0.92' stop-color='#DBE2F1' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <linearGradient
            id='paint9_linear_3878_18128'
            x1='40.4756'
            y1='89.5935'
            x2='252.219'
            y2='202.769'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.31' stop-color='#FAFBFD' />
            <stop offset='0.62' stop-color='#EEF2F8' />
            <stop offset='0.93' stop-color='#DAE1F1' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <linearGradient
            id='paint10_linear_3878_18128'
            x1='45.5423'
            y1='85.7862'
            x2='247.196'
            y2='206.648'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.31' stop-color='#FAFBFD' />
            <stop offset='0.62' stop-color='#EEF1F8' />
            <stop offset='0.93' stop-color='#DAE1F1' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <linearGradient
            id='paint11_linear_3878_18128'
            x1='50.6958'
            y1='82.3264'
            x2='242.057'
            y2='210.18'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.31' stop-color='#FAFBFD' />
            <stop offset='0.62' stop-color='#EEF1F8' />
            <stop offset='0.93' stop-color='#DAE1F1' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <linearGradient
            id='paint12_linear_3878_18128'
            x1='55.9362'
            y1='79.1993'
            x2='236.846'
            y2='213.38'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.31' stop-color='#FAFBFD' />
            <stop offset='0.62' stop-color='#EEF1F8' />
            <stop offset='0.93' stop-color='#DAE1F0' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <linearGradient
            id='paint13_linear_3878_18128'
            x1='61.22'
            y1='76.4199'
            x2='231.576'
            y2='216.231'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.31' stop-color='#FAFBFD' />
            <stop offset='0.62' stop-color='#EEF1F8' />
            <stop offset='0.94' stop-color='#D9E1F0' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <linearGradient
            id='paint14_linear_3878_18128'
            x1='66.5473'
            y1='73.9879'
            x2='226.264'
            y2='218.736'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.3' stop-color='#FAFBFD' />
            <stop offset='0.62' stop-color='#EEF1F8' />
            <stop offset='0.94' stop-color='#D9E0F0' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <linearGradient
            id='paint15_linear_3878_18128'
            x1='71.889'
            y1='71.8886'
            x2='220.922'
            y2='220.921'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' />
            <stop offset='0.3' stop-color='#FAFBFD' />
            <stop offset='0.62' stop-color='#EEF1F8' />
            <stop offset='0.94' stop-color='#D9E0F0' />
            <stop offset='1' stop-color='#D5DDEF' />
          </linearGradient>
          <radialGradient
            id='paint16_radial_3878_18128'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(0.303917 -88.073) scale(387.948 387.948)'
          >
            <stop stop-color='#BAC4DD' />
            <stop offset='0.02' stop-color='#BCC6DE' />
            <stop offset='0.27' stop-color='#D9DEEC' />
            <stop offset='0.52' stop-color='#EEF0F6' />
            <stop offset='0.77' stop-color='#FAFBFC' />
            <stop offset='1' stop-color='white' />
          </radialGradient>
          <linearGradient
            id='paint17_linear_3878_18128'
            x1='57.7996'
            y1='100.637'
            x2='125.134'
            y2='100.637'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#5042A6' />
            <stop offset='0.25' stop-color='#4D40A2' />
            <stop offset='0.47' stop-color='#433A97' />
            <stop offset='0.68' stop-color='#343085' />
            <stop offset='0.87' stop-color='#1F226C' />
            <stop offset='1' stop-color='#0E1859' />
          </linearGradient>
          <linearGradient
            id='paint18_linear_3878_18128'
            x1='106.438'
            y1='80.0238'
            x2='188.356'
            y2='80.0238'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#40B59E' />
            <stop offset='1' stop-color='#006E9D' />
          </linearGradient>
          <linearGradient
            id='paint19_linear_3878_18128'
            x1='168.027'
            y1='102.669'
            x2='231.949'
            y2='102.669'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#FC7315' />
            <stop offset='1' stop-color='#F55138' />
          </linearGradient>
          <radialGradient
            id='paint20_radial_3878_18128'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(45.6297 88.6713) scale(71.3764 71.3757)'
          >
            <stop stop-color='#BAC4DD' />
            <stop offset='0.02' stop-color='#BCC6DE' />
            <stop offset='0.27' stop-color='#D9DEEC' />
            <stop offset='0.52' stop-color='#EEF0F6' />
            <stop offset='0.77' stop-color='#FAFBFC' />
            <stop offset='1' stop-color='white' />
          </radialGradient>
          <radialGradient
            id='paint21_radial_3878_18128'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(192.756 88.6713) scale(71.3764 71.3757)'
          >
            <stop stop-color='#BAC4DD' />
            <stop offset='0.02' stop-color='#BCC6DE' />
            <stop offset='0.27' stop-color='#D9DEEC' />
            <stop offset='0.52' stop-color='#EEF0F6' />
            <stop offset='0.77' stop-color='#FAFBFC' />
            <stop offset='1' stop-color='white' />
          </radialGradient>
          <clipPath id='clip0_3878_18128'>
            <rect width='292' height='292' fill='white' />
          </clipPath>
          <clipPath id='clip1_3878_18128'>
            <rect
              width='174.149'
              height='73.563'
              fill='white'
              transform='translate(57.7996 61.5527)'
            />
          </clipPath>
          <clipPath id='clip2_3878_18128'>
            <rect
              width='205.676'
              height='64.5553'
              fill='white'
              transform='translate(42.7866 18.7656)'
            />
          </clipPath>
          <clipPath id='clip3_3878_18128'>
            <rect
              width='66.8072'
              height='14.2622'
              fill='white'
              transform='translate(112.596 27.7734)'
            />
          </clipPath>
          <clipPath id='clip4_3878_18128'>
            <rect
              width='205.676'
              height='64.5553'
              fill='white'
              transform='matrix(1 1.74846e-07 1.74846e-07 -1 43.5374 272.483)'
            />
          </clipPath>
          <clipPath id='clip5_3878_18128'>
            <rect
              width='83.3663'
              height='19.1048'
              fill='white'
              transform='translate(107.398 242.365) rotate(2.04816)'
            />
          </clipPath>
          <clipPath id='clip6_3878_18128'>
            <rect
              width='69.0591'
              height='84.8226'
              fill='white'
              transform='translate(96.0823 138.352) rotate(-42.6328)'
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Jauge;
