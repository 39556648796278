import React from "react";
import { Link } from "react-router-dom";

const linkComponent = ({ to, children, onClick }) => {
  return (
    <div className='linkComponent'>
      <Link to={to} onClick={onClick}>
        {children}
      </Link>
    </div>
  );
};

export default linkComponent;
