import React, { useState, useEffect } from "react";

const Input = ({
  info,
  placeholder,
  type = "text",
  className,
  value = "",
  onChange = null,
  saveIn = null,
  name = null,
}) => {
  const [valueInput, setValueInput] = useState(value);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem(saveIn));
    const valueF = stored && stored[name] ? stored[name] : value;
    setValueInput(valueF);
  }, [value, saveIn, name]);

  const [internalValue, setInternalValue] = useState(valueInput);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInternalValue(newValue);
    onChange && setValueInput(onChange(newValue, e));
    !onChange && setValueInput(newValue);
  };

  useEffect(() => {
    if (saveIn && name) {
      localStorage.setItem(
        saveIn,
        JSON.stringify({
          ...JSON.parse(localStorage.getItem(saveIn)),
          [name]: valueInput,
        })
      );
    }
  }, [valueInput, saveIn, name]);

  return (
    <div
      className={`inputComponent ${className || ""} ${info ? "withInfo" : ""}`}
    >
      <input
        type={type === "date" ? "text" : type}
        placeholder={placeholder}
        {...(type === "date" && {
          type: "date",
          value: internalValue,
          onFocus: (e) => (e.target.type = "date"),
          onBlur: (e) => (e.target.type = "text"),
        })}
        value={type === "date" ? internalValue : valueInput}
        onChange={handleChange}
      />
      {info && <span>{info}</span>}
    </div>
  );
};

export default Input;
