import { useNavigate } from "react-router-dom";

const Button = ({ value, type, children, to, className, onClick }) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
    if (to) {
      navigate(to);
    }
  };

  return (
    <div
      className={
        className
          ? `${className} ${
              type ? type + "ButtonComponent" : "primaryButtonComponent"
            }`
          : type
          ? type + "ButtonComponent"
          : "primaryButtonComponent"
      }
    >
      <button onClick={handleClick}>{value ? value : children}</button>
    </div>
  );
};

export default Button;
