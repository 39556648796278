import React, { useEffect, useState } from "react";
import LayoutPrimary from "../components/partials/LayoutPrimary";
import Frame from "../components/Frame";
import Title from "../components/Title";
import Input from "../components/Input";
import InputSiret from "../components/InputSiret";
import Address from "../components/Address";
import Label from "../components/Label";
import Button from "../components/Button";
import Link from "../components/Link";
import Select from "../components/Select";
import getCurrent from "../services/getCurrent";
import updateData from "../services/updateData";
import fetchData from "../services/fetchData";

const Informations = () => {
  const [company, setCompany] = useState();
  const [user, setUser] = useState();
  const [hideCompanyDetails, setHideCompanyDetails] = useState(false);
  const [newSiret, setNewSiret] = useState();

  useEffect(() => {
    getCurrent("company").then((data) => {
      console.log("getCurrent Company", data);
      setCompany({
        ...data,
        address:
          typeof data.address === "string"
            ? JSON.parse(data.address)
            : data.address,
      });
    });

    getCurrent("user").then((data) => {
      setUser({
        ...data,
        meta:
          typeof data?.meta === "string" ? JSON.parse(data.meta) : data.meta,
      });
    });
  }, []);

  const handleSave = async () => {
    const data = {
      name: company.name,
      siret: company.siret,
      address: {
        address: company.address.address,
        postal_code: company.address.postal_code,
        city: company.address.city,
      },
    };
    await updateData({
      endpoint: "/business",
      body: JSON.stringify(data),
    });
  };

  const handleSiretChange = (value) => {
    if (company.siret === value) setHideCompanyDetails(false);
    if (company.siret !== value) setHideCompanyDetails(true);
    setNewSiret(value);
    return value;
  };

  const handleGetCompany = (e) => {
    e.preventDefault();

    fetchData({
      endpoint: `/companies/siret/${newSiret}`,
      saveInNotRead: "companiesData",
    }).then((response) => {
      if (response?.operation === "success") {
        setCompany({
          ...response?.data,
          address:
            typeof response?.data.address === "string"
              ? JSON.parse(response?.data.address)
              : response?.data.address,
        });

        getCurrent("business").then((data) => {
          fetchData({
            endpoint: `/business`,
            method: "POST",
            saveInNotRead: "businessData",
            body: JSON.stringify({
              company_id: response?.data?.id,
              user_id: data?.user_id,
              job_id: data?.job_id,
              address: JSON.stringify({
                address: response?.data?.address?.address,
                postal_code: response?.data?.address?.postal_code,
                city: response?.data?.address?.city,
              }),
            }),
          }).then((response) => {
            if (response?.operation === "success") {
              getCurrent("company").then((data) => {
                setCompany({
                  ...data,
                  address:
                    typeof data.address === "string"
                      ? JSON.parse(data.address)
                      : data.address,
                });
              });

              getCurrent("user").then((data) => {
                setUser({
                  ...data,
                  meta:
                    typeof data?.meta === "string"
                      ? JSON.parse(data.meta)
                      : data.meta,
                });
              });
            }
          });
        });
      }
    });
  };

  return (
    <LayoutPrimary>
      <Frame type={2}>
        <Title title='Vos informations' />
        <Select
          placeholder='Genre'
          initialValue={user?.meta?.gender}
          options={[
            { value: "female", label: "Femme" },
            { value: "male", label: "Homme" },
          ]}
          info='Greadn ne se sert de cette information qu’à des fins statistiques.Le genre n’intervient pas dans les résultats Greadn.'
          onChange={(value) =>
            setUser({ ...user, meta: { ...user.meta, gender: value } })
          }
        ></Select>
        <Input
          placeholder='Nom'
          className='col6'
          value={user?.surname}
          onChange={(value) => setUser({ ...user, surname: value })}
        />
        <Input
          placeholder='Prénom'
          className='col6'
          value={user?.name}
          onChange={(value) => setUser({ ...user, name: value })}
        />
        <Input
          placeholder='Email'
          className='col6 sm-col12'
          value={user?.email}
          onChange={(value) => setUser({ ...user, email: value })}
        />
        {/*<InputPassword placeholder='Mot de passe' className='col6 sm-col12' />*/}
        <Input
          placeholder='Date de Naissance'
          className='col6 sm-col12'
          value={user?.meta.birthdate}
        />
        <Link to='/supprimer-mon-compte'>Supprimer mon compte &gt;</Link>
        <Title title='Votre entreprise' />
        <Label>Numéro Siret *</Label>
        <InputSiret value={company?.siret} onChange={handleSiretChange} />
        {!hideCompanyDetails && (
          <>
            <Input placeholder='Raison social' value={company?.name} />
            <Address
              address={company?.address.address}
              postalCode={company?.address.postal_code}
              city={company?.address.city}
            />{" "}
          </>
        )}
        {hideCompanyDetails && (
          <Link onClick={handleGetCompany}>Trouver cette entreprise &gt;</Link>
        )}
        <div
          className='center col12'
          style={{ marginTop: "50px", marginBottom: "50px" }}
        >
          <Button onClick={handleSave}>Enregistrer &gt;</Button>
        </div>
      </Frame>
    </LayoutPrimary>
  );
};

export default Informations;
