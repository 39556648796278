import React from "react";
import Frame from "../../Frame";
import Title from "../../Title";
import Loader from "../../Loader";
import Button from "../../Button";

const StartScreen = ({ handleNextStep }) => {
  return (
    <Frame type={3} className='center'>
      <div className='center'>
        <Loader />
      </div>
      <Title>
        Étape 2<br />
        Tarif Journalier Moyen du marché
      </Title>
      <ul>
        <li>
          Un TJM minimum de marché. À profil équivalent, 90% des TJM de
          Freelances sont plus haut que ce TJM.{" "}
        </li>
        <li>
          Un TJM maximum de marché. À profil équivalent, 90% des TJM de
          Freelances sont plus bas que ce TJM.
        </li>
      </ul>
      <p>
        Gardez à l’esprit que lorsque vous déterminez votre tarif pour une
        mission, d’autres éléments plus précis rentrent en jeu. Mais là nous
        parlons bien du TJM, pas du tarif de mission.
      </p>
      <p>
        <strong>
          Les questions posées dans cette étape visent à compléter, au juste
          nécessaire, la connaissance que Greadn a de votre profil.
        </strong>
      </p>
      <div className='center'>
        <Button
          value='Commencer &gt;'
          onClick={handleNextStep}
          className='center'
        />
      </div>
    </Frame>
  );
};

export default StartScreen;
