import Frame from "../../Frame";
import Badge from "../../Badge";
import Title from "../../Title";

const FinishBadge = () => {
  return (
    <Frame type={3} className={"center"}>
      <Badge step='3' className={"big center"}></Badge>
      <Title className={"darkBlue center"}>
        Voici{" "}
        <span className='green'>
          la fourchette de TJM de marché est fixée !
        </span>
      </Title>
      <p>
        Copier le TJM des concurrents qui récoltent le mieux, c’est tentant !
      </p>
      <p>Mais votre parcelle de clients a ses propres besoins.</p>
      <p> Et vous avez vos propres qualités. </p>
      <p>Greadn va les prendre en compte.</p>
    </Frame>
  );
};

export default FinishBadge;
