import LayoutPrimary from "../components/partials/LayoutPrimary";
import RangeRadian from "../components/RangeRadian";

const Components = () => {
  return (
    <LayoutPrimary>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <RangeRadian initialValue={50} />
      </div>
    </LayoutPrimary>
  );
};

export default Components;
