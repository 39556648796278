import React, { useEffect, useState } from "react";
import fetchData from "../../../services/fetchData";
import Frame from "../../Frame";
import Title from "../../Title";
import Button from "../../Button";
import UserProfil from "../../partials/UserProfil";

const TimeFree = ({ handleNextStep }) => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      const response = await fetchData({
        endpoint: "/users",
        method: "GET",
        saveIn: "userData",
      });
      if (response?.operation === "success") {
        const userData =
          response.typeof === "object"
            ? response?.data
            : response?.data?.filter((item) => item.id === response.userId)[0];
        setUserData(userData);
      } else {
        window.location.replace("/connexion");
      }
    };
    fetchUserData();
  }, [setUserData]);
  return (
    <Frame type={3} className={"center"}>
      <p>
        <br />
      </p>
      <UserProfil />
      <Title className={"darkBlue center"}>
        {userData.name}, la phase de calcul du TJSeuil{" "}
        <span className='green'>est terminée !</span>
      </Title>
      <div className='center'>
        <Button onClick={handleNextStep}>Obtenir mon badge &gt;</Button>
      </div>
    </Frame>
  );
};

export default TimeFree;
