import React from "react";
import Frame from "../../Frame";
import Question from "../../Question";
import RangeAC from "../../RangeAC";

const CustomerAnswers = () => {
  const job_id = JSON.parse(localStorage.getItem("businessData"))?.data.job_id;
  const jobsKnowledge = JSON.parse(localStorage.getItem("jobsKnowledge"))?.data;
  let job_label;
  if (jobsKnowledge && job_id) {
    job_label = jobsKnowledge.find((item) => item.id === job_id)?.label;
  }

  return (
    <Frame type={3} className='center'>
      <Question>Pour vos clients & vos prospects </Question>
      <p className='secondaryFont darkBlue'>
        Quelle est l’importance de ces aspects chez un Freelance dans une
        mission de {job_label} ?
      </p>
      <p>
        Greadn vous conseille, uniquement pour cette question, de solliciter
        l’avis de clients et/ou prospects sur votre marché
      </p>
      <RangeAC
        label={"La rapidité du travail"}
        saveIn='form_step3'
        name='work_speed'
      />
      <RangeAC
        label={"La qualité du travail"}
        saveIn='form_step3'
        name='work_quality'
      />
      <RangeAC
        label={"L’aisance relationnelle"}
        saveIn='form_step3'
        name='pr'
      />
      <RangeAC label={"La notoriété"} saveIn='form_step3' name='fame' />
    </Frame>
  );
};

export default CustomerAnswers;
