import React, { useEffect, useState } from "react";
import Frame from "../../Frame";
import Question from "../../Question";
import Range from "../../Range";
import RangeSMIC from "../../RangeSMIC";
import QuestionSwitch from "../../QuestionSwitch";
import fetchData from "../../../services/fetchData";

const StartScreen = ({ handleNextStep }) => {
  const prevData = JSON.parse(localStorage.getItem("form_step1") || "[]");
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const fetchDataWrapper = async () => {
      const [questionsResponse] = await Promise.all([
        fetchData({
          endpoint: "/questions",
          saveIn: "questionsKnowledge",
        }),
      ]);

      setQuestions(
        questionsResponse.data.filter((question) => question.step === "step1")
      );
    };

    fetchDataWrapper();
  }, []);

  return (
    <Frame type={3} className='center'>
      {questions.find((q) => q.id === 8)?.label && (
        <Question>{questions.find((q) => q.id === 8)?.label}</Question>
      )}
      <p>
        Si vous avez d’autres activités que celle-ci, estimez votre part de
        temps passé pour cette entreprise de freelancing.
      </p>
      <Range name='timeRate' value={40} saveIn='form_step1' />
      {questions.find((q) => q.id === 9)?.label && (
        <QuestionSwitch
          name={9}
          question={questions.find((q) => q.id === 9)?.label}
          saveIn='form_step1'
        />
      )}
      {questions.find((q) => q.id === 10)?.label && (
        <Question>{questions.find((q) => q.id === 10)?.label}</Question>
      )}
      <p>dans cette entreprise, avant imposition</p>
      <RangeSMIC name='wish_salary' saveIn='form_step1' />
      {(prevData?.company_type_id === 10 ||
        prevData?.company_type_id === 9) && (
        <div>
          <Question>Part du revenu touché en dividendes</Question>
          <Range value={10} saveIn='form_step1' name='dividends' />
        </div>
      )}
    </Frame>
  );
};

export default StartScreen;
