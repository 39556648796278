import React, { useEffect, useState } from "react";
import fetchData from "../../services/fetchData";
import Badge from "../Badge";
import getCurrent from "../../services/getCurrent";

const UserProfil = () => {
  const [user, setUser] = useState({});
  const [business, setBusiness] = useState({});
  const [job, setJob] = useState({});

  useEffect(() => {
    Promise.all([
      getCurrent("user").then(setUser),
      getCurrent("business").then(setBusiness),
    ]).then(() => {
      console.log(user);
      fetchData({
        endpoint: `/jobs`,
        saveIn: "jobsKnowledge",
      }).then((data) => {
        if (data?.operation === "success") {
          setJob(data.data.filter((item) => item.id === business.job_id)[0]);
        }
      });
    });
  }, [setUser, setBusiness, setJob]);

  return (
    <div className='userProfil primaryBlock'>
      <div>
        <img
          src='https://cdn.greadn.com/assets/images/placeholder-user.png'
          alt=''
        />
        <div className='center'>
          <p className='userName'>{user?.name}</p>
          <p className='userSurname'>{user?.surname}</p>
          <p className='userJob sm-hide'>{job?.name}</p>
        </div>
      </div>
      <div>
        <h2>Etapes Greadn</h2>
        <div className='badgeContainer'>
          <Badge label='Découvrez votre tarif seuil' step='1' active />
          <Badge label='Explorez le tarif du marché' step='2' />
          <Badge label='Identifiez votre tarif max' step='3' />
          <Badge label='Choississez la meilleure stratégie' step='4' />
          <Badge label='Obtenez vos résultats' step='5' />
        </div>
        <h2>TJM Actuel</h2>
        <p className='userTjm'>
          {localStorage.getItem("adr_initial")}
          {localStorage.getItem("adr_initial") ? "€" : ""}
        </p>
        <p className='userJob lg-hide'>{job?.name}</p>
      </div>
    </div>
  );
};

export default UserProfil;
