import React, { useState, useEffect, useRef } from "react";

const Select = ({
  options,
  name = null,
  saveIn = null,
  className = "",
  placeholder,
  info = null,
  initialValue = null,
  onChange = null,
}) => {
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const [height, setHeight] = useState(21);
  const [position, setPosition] = useState(0);
  const [positionContainer, setPositionContainer] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const isFirstRender = useRef(true);

  useEffect(() => {
    const index = options.findIndex((option) => option.value === initialValue);
    if (index !== -1) {
      setPosition(index);
      setSelectedValue(initialValue);
    }
  }, [initialValue, options]);

  const handleOptionClick = (value, pos = 0) => {
    setSelectedValue(value);
    setHeight(21);
    setPosition(pos);
    setIsOpen(false);
  };

  const handleClick = (event) => {
    const component = event.target.closest(".selectComponent");
    if (isOpen) {
      component.classList.remove("open");
      setPositionContainer(0);
      setHeight(21);
      setIsOpen(false);
      onChange && onChange(selectedValue, event);
    } else {
      component.classList.add("open");
      setHeight(options.length * 29);
      setPositionContainer(position * 29);
      setPosition(0);
      component.querySelector(".placeholder")?.classList.add("hide");
      setIsOpen(true);
    }
  };

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem(saveIn));

    if (stored && stored[name]) {
      setSelectedValue(stored[name]);
      setPositionContainer(stored[`${name}_pos`] * 29);
      setPosition(stored[`${name}_pos`]);
      setIsOpen(false);
    }
    isFirstRender.current = false;
  }, [name, saveIn]);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }

    if (saveIn && name && selectedValue !== "") {
      const positionName = `${name}_pos`;
      localStorage.setItem(
        saveIn,
        JSON.stringify({
          ...JSON.parse(localStorage.getItem(saveIn)),
          [name]: selectedValue,
          [positionName]: position,
        })
      );
    }
  }, [selectedValue, position, saveIn, name]);

  useEffect(() => {
    if (isOpen) {
      setHeight(options.length * 29);
      setPositionContainer(position * 29);
    } else {
      setHeight(21);
      setPositionContainer(0);
    }
  }, [isOpen, options.length, position]);

  return (
    <div className={info ? "selectWithInfo" : ""}>
      <div
        className={`selectComponent ${className || ""} ${isOpen ? "open" : ""}`}
        onClick={handleClick}
      >
        <input
          type='hidden'
          name={name}
          value={selectedValue}
          onInput={() => handleOptionClick("", 0)}
        />
        {(placeholder && !JSON.parse(localStorage.getItem(saveIn))?.[name]) ||
          (!selectedValue && <div className='placeholder'>{placeholder}</div>)}
        <div
          className='optionsContainer'
          style={{
            height: `${height}px`,
            transform: `translateY(-${positionContainer}px)`,
          }}
        >
          {options.map((option, index) => (
            <div
              key={option.value}
              className={`option ${
                selectedValue === option.value ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option.value, index)}
              style={{
                transform: `translateY(-${position * 29}px)`,
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
      {info && <span>{info}</span>}
    </div>
  );
};

export default Select;
