import fetchData from "../../../services/fetchData";
import updateUserMeta from "../../../services/updateUserMeta";

const getFormData = () => {
  return JSON.parse(localStorage.getItem("form_step1"));
};

const getBusinessId = () => {
  const businessLS = JSON.parse(localStorage.getItem("businessData"));
  const business =
    businessLS.typeof === "object"
      ? businessLS.data
      : businessLS?.filter((b) => b.user_id === businessLS.userId).pop();

  return business.id;
};

const getCompanyId = () => {
  const companyLS = JSON.parse(localStorage.getItem("companyData"));
  const company =
    companyLS.typeof === "object"
      ? companyLS.data
      : companyLS.filter((c) => c.user_id === companyLS.userId).pop();

  return company.id;
};

const CompanyDetails = async () => {
  const CompanyDetails = getFormData();

  const companyId = getCompanyId();

  return fetchData({
    endpoint: `/companies/${companyId}`,
    method: "PUT",
    body: JSON.stringify({
      company_type_id: CompanyDetails.company_type_id,
    }),
  });
};

const CompanyCharges = async () => {
  const timeRate = getFormData();

  const businessId = getBusinessId();

  return Promise.all([
    fetchData({
      endpoint: `/business/${businessId}`,
      method: "PUT",
      body: JSON.stringify({
        time_rate: timeRate.timeRate,
      }),
    }),
    updateUserMeta("wish_salary", timeRate.wish_salary),
    fetchData({
      endpoint: "/business_answers",
      method: "POST",
      body: JSON.stringify({
        business_id: businessId,
        question_id: 9,
        answer_id: timeRate[9],
      }),
    }),
  ]);
};

const parse = async () => {
  return Promise.all([CompanyDetails(), CompanyCharges()]).then(() => {
    Promise.resolve({
      operation: "success",
      errorMessage: "",
    });
  });
};

export default parse;
