import React from "react";
import Frame from "../../Frame";
import Title from "../../Title";
import Loader from "../../Loader";
import BigButton from "../../BigButton";

const Start = ({ handleNextStep }) => {
  return (
    <Frame type={3} className='center'>
      <div className='center'>
        <Loader />
      </div>
      <Title>Initialisation de Greadn</Title>
      <p>
        L’initialisation n’est à faire qu’une seule fois et ne vous sera pas
        redemandée.
      </p>
      <p>
        Dans cette étape, Greadn récolte les informations indispensables pour
        mieux vous cerner et appréhender vos connaissances sur le TJM par
        rapport aux autres Freelances.
      </p>
      <p>
        <strong>
          Les questions posées concernent vous-même, votre entreprise et votre
          activité.
        </strong>
      </p>

      <BigButton content='Démarrer' onClick={handleNextStep} />
    </Frame>
  );
};

export default Start;
