const Title = ({ title = null, children = null, className = "" }) => {
  return (
    <div className={className}>
      <h1>{title || children}</h1>
    </div>
  );
};

Title.defaultProps = {
  className: "",
};

export default Title;
