import React, { useState, useEffect } from "react";

const numberFormarter = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const SMIC = 1383;
const Range = ({
  min = SMIC,
  max = SMIC * 10,
  step = 1,
  value = SMIC * 2,
  onChange,
  name = null,
  saveIn = null,
}) => {
  const [rangeValue, setRangeValue] = useState(() => {
    try {
      const prevData = JSON.parse(localStorage.getItem(saveIn) || "{}");
      if (prevData && (prevData[name] || prevData[name] === 0)) {
        return prevData[name] > max ? max : prevData[name];
      }
    } catch (error) {
      console.error(error);
    }
    return value > max ? max : value;
  });
  const [leftValue, setLeftValue] = useState(
    ((rangeValue - min) / (max - min)) * 100
  );

  useEffect(() => {
    if (saveIn && name) {
      localStorage.setItem(
        saveIn,
        JSON.stringify({
          ...JSON.parse(localStorage.getItem(saveIn)),
          [name]: rangeValue,
        })
      );
    }
  }, [saveIn, name, rangeValue]);

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value);
    setRangeValue(newValue);
    setLeftValue(((newValue - min) / (max - min)) * 100);
    onChange && onChange(newValue);
  };

  return (
    <div className='rangeComponent'>
      <input
        type='range'
        min={min}
        max={max}
        step={step}
        value={rangeValue}
        onChange={handleChange}
        className='range-input'
      />

      <div className='range-track' style={{ width: `${leftValue}%` }}></div>
      <div className='range-labels'>
        <span className='range-min'>x1 SMIC</span>
        <span className='range-max'>x10 SMIC</span>
      </div>
      <div className='bg'></div>
      <div className='secondaryBlock' style={{ padding: `7px 7px 5px` }}>
        <p className='secondaryFont'>
          ≈ {numberFormarter(rangeValue)} € / mois
        </p>
      </div>
    </div>
  );
};

export default Range;
