import Frame from "../../Frame";
import Question from "../../Question";
import RangeLangue from "../../RangeLangue";

const StartScreen = ({ handleNextStep }) => {
  return (
    <Frame type={3} className='center'>
      <Question>Quelles langues maîtrisez-vous ?</Question>
      <p>
        Greadn utilise l’échelle de cotation internationale en langues. Elle va
        de A1 : débutant à C2 : Bilingue.
      </p>

      <RangeLangue label='Français' name='francais' saveIn='form_step2' />
      <RangeLangue label='Anglais' name='anglais' saveIn='form_step2' />
      <RangeLangue label='Autre' name='autreLangue' saveIn='form_step2' />
    </Frame>
  );
};

export default StartScreen;
