import React, { useState, useEffect } from "react";
import LayoutPrimary from "../components/partials/LayoutPrimary";
import Frame from "../components/Frame";
import Link from "../components/Link";
import Freelance from "../components/steps/typesaccounts/Freelance";
import Lancer from "../components/steps/typesaccounts/Lancer";
import Entreprise from "../components/steps/typesaccounts/Entreprise";
import getCurrent from "../services/getCurrent";

const TypesAccount = () => {
  const [typeData, setTypeData] = useState("");

  useEffect(() => {
    const getTypeData = async () => {
      const userData = await getCurrent("user");
      userData.meta = JSON.parse(userData.meta);
      if (userData?.meta?.type === undefined) {
        window.location.replace("/init");
      } else {
        setTypeData(userData.meta.type);
      }
    };
    getTypeData();
  }, []);

  return (
    <LayoutPrimary>
      <Frame type={3}>
        <p>
          <br />
        </p>
        {typeData === "freelance" && <Freelance />}
        {typeData === "freelance-lancer" && <Lancer />}
        {typeData === "entreprise-freelance" && <Entreprise />}

        <p className='center'>
          <br />
          <Link to='/init'>&larr; Retour </Link>
        </p>
      </Frame>
    </LayoutPrimary>
  );
};

export default TypesAccount;
