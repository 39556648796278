import React, { useState } from "react";
import UserProfil from "./UserProfil";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";

function LayoutPrimary({ children, className, bg }) {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  return (
    <div className='layoutPrimary'>
      <header>
        <Link to='/'>
          {" "}
          <img
            src='https://cdn.greadn.com/assets/images/logo-greadn.svg'
            alt='Greadn'
          />
        </Link>

        <div className='btnNav sm-hide'>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
        </div>
        <div className='btnProfil lg-hide' onClick={toggleNav}></div>
      </header>
      <aside className={navOpen ? "show" : "initial"}>
        <UserProfil />
        <Navigation />
      </aside>
      <div className='container'>
        {bg ? <div className='bg-primary'></div> : null}
        {children}
      </div>
    </div>
  );
}

export default LayoutPrimary;
