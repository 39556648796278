import React, { useState, useEffect } from "react";
import fetchData from "../services/fetchData";
import Input from "../components/Input";
import { Link } from "react-router-dom";
import BigButton from "../components/BigButton";

const LoginPage2 = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (localStorage.getItem("isLoggedIn") === "true") {
      window.location.replace("/");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    fetchData({
      endpoint: "/auth",
      method: "POST",
      body: JSON.stringify({
        email,
        password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response?.operation === "success") {
          // Login successful

          const userData =
            response.typeof === "object"
              ? response?.data
              : response?.data?.filter(
                  (item) => item.id === response.userId
                )[0];

          localStorage.setItem("userToken", response.data.token);
          localStorage.setItem("userData", JSON.stringify(userData));
          onLogin();
        } else {
          // Login failed
          setError("Email ou mot de passe incorrect.");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className='layoutPrimary'>
      <header>
        <Link to='/'>
          {" "}
          <img
            src='https://cdn.greadn.com/assets/images/logo-greadn.svg'
            alt='Greadn'
          />
        </Link>
      </header>
      <div className='container'>
        <div className='bg-secondary'></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            maxWidth: "400px",
          }}
        >
          <h2>Se connecter</h2>
          <p>
            Entrez votre adresse email pour vous connecter ou créez votre compte
            personnel.
          </p>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <div>
              <Input
                placeholder={"Email"}
                type='text'
                name='email'
                saveIn='form_login'
                onChange={(e) => setEmail(e)}
              />
            </div>
            <div>
              <Input
                placeholder={"Password"}
                type='password'
                name='password'
                saveIn='form_login'
                onChange={(e) => setPassword(e)}
              />
            </div>
            <div style={{ marginTop: "80px" }}></div>
            <BigButton content='Démarrer' type='submit'></BigButton>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage2;
