import Frame from "../../Frame";
import Badge from "../../Badge";
import Title from "../../Title";

const TimeFree = ({ handleNextStep }) => {
  return (
    <Frame type={3} className={"center"}>
      <Badge step='2' className={"big center"}></Badge>
      <Title className={"darkBlue center"}>
        Voici{" "}
        <span className='green'>votre Tarif Journalier Seuil est fixé !</span>
      </Title>
      <p>
        Bravo !<br />
        <br /> Vous n’êtes pas seul dans le verger. Voyons comment vos
        concurrents dosent leur TJM et faisons croître un peu cette jeune
        pousse.
      </p>
    </Frame>
  );
};

export default TimeFree;
