import React, { useEffect, useState } from "react";
import Frame from "../../Frame";
import Question from "../../Question";
import RadioGroup from "../../RadioGroup";
import BoxAlert from "../../BoxAlert";
import fetchData from "../../../services/fetchData";

const Question2 = () => {
  const [adrDecisionValue, setAdrDecisionValue] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [alreadyAnswered, setAlreadyAnswered] = useState([]);

  useEffect(() => {
    const fetchDataWrapper = async () => {
      const [questionsResponse, answersResponse] = await Promise.all([
        fetchData({
          endpoint: "/questions",
          saveIn: "questionsKnowledge",
        }),
        fetchData({
          endpoint: "/answers",
          saveIn: "answersKnowledge",
        }),
      ]);

      setQuestions(
        questionsResponse.data.filter(
          (question) => question.step === "init_end"
        )
      );
      setAnswers(answersResponse.data);
    };

    fetchDataWrapper();
  }, []);

  useEffect(() => {
    const fetchUsersAnswers = async () => {
      const usersAnswersResponse = await fetchData({
        endpoint: "/users_answers",
        saveIn: "usersAnswers",
      });

      const usersAnswers = usersAnswersResponse.data.filter(
        (answer) => answer.user_id === usersAnswersResponse.userId
      );

      const questionsId = questions.map((question) => question.id);

      setAlreadyAnswered(
        usersAnswers.filter((userAnswer) =>
          questionsId.includes(userAnswer.question_id)
        )
      );
    };

    questions.length > 0 && fetchUsersAnswers();
  }, [questions]);

  return (
    <Frame type={3}>
      {questions &&
        questions.map((question) => (
          <div>
            <Question>{question.label}</Question>
            {answers && (
              <RadioGroup
                name='adrDecision'
                items={answers
                  .filter((answer) => answer.question_id === question.id)
                  .map((answer) => {
                    return { label: answer.label, value: answer.id };
                  })}
                value={adrDecisionValue || alreadyAnswered[0]?.answer_id}
                onChange={(value) => {
                  setAdrDecisionValue(value);
                }}
              />
            )}
          </div>
        ))}

      <BoxAlert>
        Vérifiez bien que les informations rentrées sont correctes car après
        avoir cliqué sur suivant vous ne pourrez plus les modifier.
      </BoxAlert>
    </Frame>
  );
};

export default Question2;
