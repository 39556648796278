import React, { useState, useEffect, useRef } from "react";

const Switch = ({
  name,
  handleChange = () => {},
  true_id = null,
  false_id = null,
  initialValue = null,
}) => {
  const [clicked, setClicked] = useState(
    initialValue === true_id ? true : false
  );
  const [clickedRef, setClickedRef] = useState(clicked);
  const randomName = Math.random().toString(36).substring(2, 15);
  const useName = name || randomName;

  const handleClick = () => {
    setClicked(!clicked);
    setClickedRef(!clickedRef);

    if (!clickedRef && true_id) handleChange(true_id);
    if (clickedRef && false_id) handleChange(false_id);
    if (true_id === false_id) handleChange(!clickedRef);
  };

  useEffect(() => {
    console.log("switch", initialValue, clicked);
    if (initialValue && initialValue !== clickedRef.current) {
      setClicked(initialValue === true_id ? true : false);
      setClickedRef(initialValue === true_id ? true : false);
    }
  }, [initialValue, true_id, false_id, handleChange]);

  return (
    <div
      className={`switchComponent ${clicked ? "" : "false"}`}
      onClick={handleClick}
    >
      <div className='switch'></div>
      <input type='hidden' name={useName} value={clicked} readOnly />
    </div>
  );
};

export default Switch;
