import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage2 from "./pages/LoginPage2";
import HomePage from "./pages/HomePage";
import Components from "./pages/Components";
import InitParcours from "./pages/InitParcours";
import Informations from "./pages/Informations";
import DeleteAccount from "./pages/DeleteAccount";
import TypesAccount from "./pages/TypesAccount";
import PaiementsFactures from "./pages/PaiementsFactures";
import "./App.css";
import Step0 from "./pages/Step0";
import Step1 from "./pages/Step1";
import Step2 from "./pages/Step2";
import Step3 from "./pages/Step3";
import Step4 from "./pages/Step4";
import ProtectedRoute from "./services/ProtectedRoute";
import handleLogin from "./services/handleLogin";
import fetchData from "./services/fetchData";
import Logout from "./pages/Logout";

const App = () => {
  useEffect(() => {
    if (localStorage.getItem("userToken") !== null) {
      Promise.all([
        fetchData({
          endpoint: `/business`,
          saveIn: "businessData",
        }),
        fetchData({
          endpoint: `/companies`,
          saveIn: "companiesData",
        }),
        fetchData({
          endpoint: `/jobs`,
          saveIn: "jobsKnowledge",
        }),
        fetchData({
          endpoint: `/diplomas`,
          saveIn: "diplomasKnowledge",
        }),
        fetchData({
          endpoint: `/languages`,
          saveIn: "languagesKnowledge",
        }),
        fetchData({
          endpoint: `/questions`,
          saveIn: "questionsKnowledge",
        }),
        fetchData({
          endpoint: `/answers`,
          saveIn: "answersKnowledge",
        }),
        fetchData({
          endpoint: `/companies_type`,
          saveIn: "companiesTypeKnowledge",
        }),
        fetchData({
          endpoint: `/strategies`,
          saveIn: "strategiesKnowledge",
        }),
        fetchData({
          endpoint: `/default_activities`,
          saveIn: "defaultActivitiesKnowledge",
        }),
        fetchData({
          endpoint: `/default_charges`,
          saveIn: "defaultChargesKnowledge",
        }),
        fetchData({
          endpoint: "/users",
        }).then((response) => {
          if (response?.operation === "success") {
            const userData =
              response.typeof === "object"
                ? response?.data
                : response?.data?.filter(
                    (item) => item.id === response.userId
                  )[0];
            localStorage.setItem("userDataClean", JSON.stringify(userData));
          }
        }),
      ]);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path='/connexion'
          element={<LoginPage2 onLogin={handleLogin} />}
        />
        <Route
          element={
            <ProtectedRoute
              user={localStorage.getItem("userToken") ? true : false}
            />
          }
        >
          <Route path='/' element={<HomePage />} />
          <Route path='/components' element={<Components />} />
          <Route path='/init' element={<InitParcours />} />
          <Route path='/step0' element={<Step0 />} />
          <Route path='/step1' element={<Step1 />} />
          <Route path='/step2' element={<Step2 />} />
          <Route path='/step3' element={<Step3 />} />
          <Route path='/step4' element={<Step4 />} />
          <Route path='/informations' element={<Informations />} />
          <Route path='/types-account' element={<TypesAccount />} />
          <Route path='/paiements-factures' element={<PaiementsFactures />} />
          <Route path='/supprimer-mon-compte' element={<DeleteAccount />} />
          <Route path='/logout' element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
