import React, { useEffect, useState } from "react";
import Frame from "../../Frame";
import Question from "../../Question";
import RadioGroup from "../../RadioGroup";
import fetchData from "../../../services/fetchData";

const GeneralAnswers = () => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [answersValues, setAnswersValues] = useState(
    new Array(questions.length).fill(null)
  );
  const [alreadyAnswered, setAlreadyAnswered] = useState([]);

  useEffect(() => {
    const fetchDataWrapper = async () => {
      const response = await fetchData({
        endpoint: "/questions",
        saveIn: "questionsKnowledge",
      });

      const filteredQuestions = response.data.filter(
        (question) => question.step === "step3_general"
      );

      setQuestions(filteredQuestions);

      const answersResponse = await fetchData({
        endpoint: "/answers",
        saveIn: "answersKnowledge",
      });

      if (answersResponse?.operation === "success") {
        const filteredAnswers = answersResponse.data.filter((answer) =>
          filteredQuestions.some(
            (question) => question.id === answer.question_id
          )
        );
        setAnswers(filteredAnswers);
      }
    };

    fetchDataWrapper();
  }, []);

  useEffect(() => {
    const fetchUsersAnswers = async () => {
      const usersAnswersResponse = await fetchData({
        endpoint: "/users_answers",
        saveIn: "usersAnswers",
      });

      const usersAnswers = usersAnswersResponse.data.filter(
        (answer) => answer.user_id === usersAnswersResponse.userId
      );

      const questionsId = questions.map((question) => question.id);

      const answersResult = questions.map((question, index) => {
        const alreadyAnsweredForQuestion = usersAnswers.find(
          (userAnswer) => userAnswer.question_id === question.id
        );
        return alreadyAnsweredForQuestion?.answer_id || null;
      });
      setAnswersValues(answersResult);
      setAlreadyAnswered(usersAnswers);
    };

    questions.length > 0 && fetchUsersAnswers();
  }, [questions]);

  return (
    <Frame type={3} className='center'>
      {questions &&
        questions.map((question, index) => (
          <div>
            <Question>{question.label}</Question>
            {answers && (
              <RadioGroup
                key={question.id}
                name={`question_${question.id}`}
                saveIn={`form_step3`}
                items={answers
                  .filter((answer) => answer.question_id === question.id)
                  .map((answer) => {
                    return { label: answer.label, value: answer.id };
                  })}
                value={answersValues[index]}
                onChange={(value) => {
                  const newAnswersValues = [...answersValues];
                  newAnswersValues[index] = value;
                  setAnswersValues(newAnswersValues);
                }}
              />
            )}
          </div>
        ))}
    </Frame>
  );
};

export default GeneralAnswers;
