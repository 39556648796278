import React, { useEffect, useState } from "react";
import fetchData from "../services/fetchData";
import Title from "../components/Title";
import LayoutPrimary from "../components/partials/LayoutPrimary";
import Jauge from "../components/Jauge";
import Button from "../components/Button";

const HomePage = () => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      const response = await fetchData({
        endpoint: "/users",
        method: "GET",
        saveIn: "userData",
      });
      if (response?.operation === "success") {
        const userData =
          response.typeof === "object"
            ? response?.data
            : response?.data?.filter((item) => item.id === response.userId)[0];
        setUserData(userData);
      } else {
        window.location.replace("/connexion");
      }
    };
    fetchUserData();
  }, [setUserData]);

  return (
    <LayoutPrimary bg='primary'>
      <div className='center'>
        <Title>
          {userData.name}, voici votre jauge de Tarif Journalier Greadn !
        </Title>
        <Jauge
          choice={localStorage.getItem("adr_initial")}
          max='500'
          min='300'
        />
        <div className='center'>
          <Button to='/init'>Avancer &gt;</Button>
        </div>
      </div>
    </LayoutPrimary>
  );
};

export default HomePage;
