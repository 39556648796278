import Frame from "../../Frame";
import RangeCommunication from "../../RangeCommunication";

const Communication1 = ({ handleNextStep }) => {
  return (
    <Frame type={3} className='center'>
      <RangeCommunication
        label='Je souris facilement'
        name='sourir'
        saveIn='form_step3'
      />
      <RangeCommunication
        label='J’ai une attitude ouverte (posture générale)'
        name='ouvert'
        saveIn='form_step3'
      />
      <RangeCommunication
        label='Je peux me rapprocher physiquement des personnes à qui je parle quand cela est souhaitable, sans éprouver de gêne'
        name='rapprochement'
        saveIn='form_step3'
      />
    </Frame>
  );
};

export default Communication1;
