import React from "react";
import LayoutPrimary from "../components/partials/LayoutPrimary";
import StepContainer from "../components/StepContainer";
import Step from "../components/Step";
import Title from "../components/Title";
import Loader from "../components/Loader";
import Frame from "../components/Frame";
import StartScreen from "../components/steps/step3/StartScreen";
import GeneralAnswers from "../components/steps/step3/GeneralAnswers";
import Communication from "../components/steps/step3/Communication";
import Communication1 from "../components/steps/step3/Communication1";
import FinishBadge from "../components/steps/step3/FinishBadge";
import CustomerAnswers from "../components/steps/step3/CustomerAnswers";
import Communication2 from "../components/steps/step3/Communication2";
import Communication3 from "../components/steps/step3/Communication3";
import FinishProfil from "../components/steps/step3/FinishProfil";

const Step3 = () => {
  return (
    <LayoutPrimary>
      <StepContainer parcoursName='step3' nextRoute='/step4'>
        <Step>
          <Frame type={3} className='center'>
            <div className='center'>
              <Loader animate={true} />
            </div>

            <Title>
              Étape 3<br />
              Tarif Journalier Plafond
            </Title>
            <p>
              Greadn a évalué, à l’étape 2, le Tarif Journalier Seuil (TJS), qui
              représente le tarif en dessous duquel vous ne devriez pas
              descendre.
            </p>
          </Frame>
        </Step>
        <Step displayButton={false}>
          <StartScreen />
        </Step>
        <Step displayButton='both'>
          <CustomerAnswers />
        </Step>
        <Step displayButton='both'>
          <GeneralAnswers />
        </Step>
        <Step displayButton='both'>
          <Communication />
        </Step>
        <Step displayButton='both'>
          <Communication1 />
        </Step>
        <Step displayButton='both'>
          <Communication2 />
        </Step>
        <Step
          displayButton='both'
          beforeNextStep={async () => {
            return;
          }}
        >
          <Communication3 />
        </Step>
        <Step displayButton={false}>
          <FinishProfil />
        </Step>
        <Step>
          <FinishBadge />
        </Step>
        <Step>{/* Next Parcours */}</Step>
      </StepContainer>
    </LayoutPrimary>
  );
};

export default Step3;
