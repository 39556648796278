import React, { useEffect, useState } from "react";
import Frame from "../../Frame";
import Select from "../../Select";
import Question from "../../Question";
import fetchData from "../../../services/fetchData";

const Diploma = () => {
  const [diplomas, setDiplomas] = useState([]);
  const [job, setJob] = useState({});
  const [business, setBusiness] = useState({});

  useEffect(() => {
    fetchData({
      endpoint: `/business`,
      saveIn: "businessData",
    }).then((response) => {
      if (response?.operation === "success") {
        setBusiness(response);
      }
    });
  }, []);

  useEffect(() => {
    const fetchJob = async () => {
      if (Object.values(business).length > 0) {
        const currentBusiness =
          business.typeof === "object"
            ? business.data
            : business.data?.find((b) => b.user_id === business.userId);
        if (currentBusiness) {
          const response = await fetchData({
            endpoint: `/jobs`,
            saveIn: "jobsKnowledge",
          });
          if (response?.operation === "success") {
            const job = response.data.find(
              (job) => job.id === currentBusiness.job_id
            );
            setJob(job);
          }
        }
      }
    };
    fetchJob();
  }, [business]);

  useEffect(() => {
    fetchData({
      endpoint: `/diplomas`,
      saveIn: "diplomasKnowledge",
    }).then(
      (response) => {
        if (response?.operation === "success") {
          setDiplomas(response.data);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }, []);

  return (
    <Frame type={3} className='center'>
      <Question>Quel est votre diplôme le plus élevé ?</Question>
      <Select
        name='maxDiploma'
        saveIn='form_step2'
        options={diplomas.map((d) => ({ value: d.id, label: d.label }))}
      />

      <Question>Quelle est votre formation pour {job?.label} ?</Question>
      <Select
        name='jobDiploma'
        saveIn='form_step2'
        options={diplomas.map((d) => ({ value: d.id, label: d.label }))}
      />
    </Frame>
  );
};

export default Diploma;
