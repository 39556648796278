const BigButton = ({ content, onClick }) => {
  return (
    <div className='bigButtonComponent'>
      <button onClick={onClick}>
        <img src='https://cdn.greadn.com/assets/images/logo-ico.svg' alt='' />
        {content}
      </button>
    </div>
  );
};

export default BigButton;
