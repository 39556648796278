import React from "react";

const LetterSeparatedInput = ({ value }) => {
  // Split the input value into an array of characters
  const characters = typeof value === "string" ? value.split("") : [];

  return (
    <div className='letterContainer'>
      {/* Map over the characters array and wrap each character with <span> */}
      {characters.map((char, index) => (
        <span key={index} className='letter-span'>
          {char}
        </span>
      ))}
    </div>
  );
};

export default LetterSeparatedInput;
