import Frame from "../../Frame";
import Question from "../../Question";
import RangeCharge from "../../RangeCharge";

const StartScreen = ({ handleNextStep }) => {
  return (
    <Frame type={3} className='center'>
      <Question>Frais d'entreprise</Question>
      <p>
        Estimez les frais liés à tous vos métiers exercés dans cette entreprise.
        Pour plus de détails cliquez sur “modifier en détails”.
      </p>
      <h2 style={{ fontSize: "20px" }}>Frais fixes</h2>
      <p>
        <em>[components manquant]</em>
      </p>

      <Question>Provision de frais fixes</Question>
      <p>
        Conseil : Conservez en provision au moins 10% des frais, c’est une bonne
        pratique de gestion
      </p>
      <RangeCharge
        inEuro={JSON.parse(localStorage.getItem("form_step1")).wish_salary}
        name='rate_charge_fixe'
        saveIn='form_step1'
        value='10'
      />
    </Frame>
  );
};

export default StartScreen;
