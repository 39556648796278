const Navigation = () => {
  return (
    <nav>
      <a href='/'>Accueil</a>
      <a href='/informations'>Informations</a>
      <a href='/resultats'>Résultats</a>
      <a href='/documentation'>Documentation</a>
      <a href='/contact'>Contact</a>
      <a href='/paiements-factures'>Paiements & factures</a>
      <a href='/logout'>Se deconnecter</a>
    </nav>
  );
};

export default Navigation;
