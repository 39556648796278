import React, { useEffect, useState } from "react";
import Frame from "../../Frame";
import Question from "../../Question";
import RangeRadian from "../../RangeRadian";
import fetchData from "../../../services/fetchData";

const Offers = () => {
  const [job, setJob] = useState({});
  const [business, setBusiness] = useState({});

  useEffect(() => {
    fetchData({
      endpoint: `/business`,
      saveIn: "businessData",
    }).then((response) => {
      if (response?.operation === "success") {
        setBusiness(response);
      }
    });
  }, []);

  useEffect(() => {
    const fetchJob = async () => {
      if (Object.values(business).length > 0) {
        const currentBusiness =
          business.typeof === "object"
            ? business.data
            : business.data?.find((b) => b.user_id === business.userId);
        if (currentBusiness) {
          const response = await fetchData({
            endpoint: `/jobs`,
            saveIn: "jobsKnowledge",
          });
          if (response?.operation === "success") {
            const job = response.data.find(
              (job) => job.id === currentBusiness.job_id
            );
            setJob(job);
          }
        }
      }
    };
    fetchJob();
  }, [business]);
  return (
    <Frame type={3} className={"center"}>
      <Question>L'offre en {job.label}</Question>
      <p className='secondaryFont darkBlue'>
        En moyenne, combien de Freelances répondent à une offre de mission de la
        part de vos prospects/clients ?
        <br />
        <br />
      </p>
      <RangeRadian initialValue={5} name='offers' saveIn='form_step2' />
    </Frame>
  );
};

export default Offers;
