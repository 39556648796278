import React from "react";
import LayoutPrimary from "../components/partials/LayoutPrimary";
import StepContainer from "../components/StepContainer";
import Step from "../components/Step";
import Title from "../components/Title";
import Loader from "../components/Loader";
import Frame from "../components/Frame";
import StartScreen from "../components/steps/step1/StartScreen";
import CompanyDetails from "../components/steps/step1/CompanyDetails";
import TimeRate from "../components/steps/step1/TimeRate";
import CompanyCharges from "../components/steps/step1/CompanyCharges";
import CompanyCharges2 from "../components/steps/step1/CompanyCharges2";
import TimeFree from "../components/steps/step1/TimeFree";
import Finish1 from "../components/steps/step1/Finish1";
import FinishBadge from "../components/steps/step1/FinishBadge";
import parse from "../components/steps/step1/parse";

const Step1 = () => {
  return (
    <LayoutPrimary>
      <StepContainer parcoursName='step1' nextRoute='/step2'>
        <Step>
          <Frame type={3} className='center'>
            <div className='center'>
              <Loader animate={true} />
            </div>

            <Title>
              Étape 1<br />
              Tarif Journalier Seuil
            </Title>
            <p>
              Greadn va évaluer le Taux Journalier
              <br />
              en dessous duquel :
            </p>
            <ul>
              <li>soit votre activité n’est plus rentable </li>
              <li>
                soit vous ne pourrez pas vivre correctement de votre activité
              </li>
            </ul>
            <p>
              Par conséquent il est préférable que vos tarifs de mission ne
              descendent pas en dessous.
            </p>
          </Frame>
        </Step>
        <Step displayButton={false}>
          <StartScreen />
        </Step>
        <Step
          displayButton='both'
          beforeNextStep={() => {
            let prevData = JSON.parse(
              localStorage.getItem("form_step1") || "[]"
            );

            let company_type_id = 1;

            if (
              prevData.CompanyType === "micro" &&
              prevData.CompanyRegime === "micro"
            ) {
              company_type_id = 2;
            }

            if (prevData.CompanyType === "portage") {
              company_type_id = 10;
            }

            if (prevData.CompanyType === "eurl") {
              company_type_id = 3;
            }

            if (prevData.CompanyType === "sasu") {
              company_type_id = 9;
            }

            if (typeof prevData !== "object") {
              prevData = [];
            }

            localStorage.setItem(
              "form_step1",
              JSON.stringify({
                ...prevData,
                company_type_id: company_type_id,
              })
            );

            return Promise.resolve();
          }}
        >
          <CompanyDetails />
        </Step>
        <Step displayButton='both'>
          <TimeRate />
        </Step>
        <Step displayButton='both'>
          <CompanyCharges />
        </Step>
        <Step displayButton='both'>
          <CompanyCharges2 />
        </Step>
        <Step
          displayButton='both'
          beforeNextStep={() => {
            return parse();
          }}
        >
          <TimeFree />
        </Step>
        <Step displayButton={false}>
          <Finish1 />
        </Step>
        <Step>
          <FinishBadge />
        </Step>
        <Step>{/* Next Parcours */}</Step>
      </StepContainer>
    </LayoutPrimary>
  );
};

export default Step1;
