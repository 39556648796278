import fetchData from "./fetchData";
const getCurrent = async (endpoint = null) => {
  if (endpoint == null) {
    throw new Error("endpoint is required");
  }

  switch (endpoint) {
    case "user":
      const userDataLS = await fetchData({
        endpoint: `/users`,
        saveIn: "userData",
      });
      return userDataLS.typeof === "object"
        ? userDataLS?.data
        : userDataLS?.data?.filter((item) => item.id === userDataLS.userId)[0];

    case "company":
      const companiesDataLS = await fetchData({
        endpoint: `/companies`,
        saveIn: "companiesData",
      });

      const currentBusiness = await getCurrent("business");

      return companiesDataLS.typeof === "object"
        ? companiesDataLS?.data
        : companiesDataLS?.data?.filter(
            (company) => company.id === currentBusiness.company_id
          )[0];

    case "business":
      const businessDataLS = await fetchData({
        endpoint: `/business`,
        saveIn: "businessData",
      });
      return businessDataLS.typeof === "object"
        ? businessDataLS?.data
        : businessDataLS?.data
            ?.filter((business) => business.user_id === businessDataLS.userId)
            .reverse()[0];
    default:
      throw new Error(`${endpoint} not found`);
  }
};

export default getCurrent;
