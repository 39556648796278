const fetchData = async ({
  endpoint = null,
  method = "GET",
  body = null,
  saveIn = null,
  saveInNotRead = null,
}) => {
  if (saveIn && localStorage.getItem(saveIn)) {
    const data = JSON.parse(localStorage.getItem(saveIn));
    if (data.operation === "success") return data;
  }

  if (endpoint == null) {
    throw new Error("endpoint is required");
  }

  const headers = {
    "Content-Type": "application/json",
  };

  if (
    localStorage.getItem("userToken") !== null &&
    localStorage.getItem("userToken") !== ""
  ) {
    headers["Authorization"] = `Bearer ${localStorage.getItem("userToken")}`;
  }

  const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
    method,
    body,
    headers,
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));

  if ((saveIn || saveInNotRead) && response?.operation === "success") {
    localStorage.setItem(saveIn || saveInNotRead, JSON.stringify(response));
  } else if (response?.operation !== "success") {
    console.error("Fetch Error");
  }

  return response;
};

export default fetchData;
