import React, { useEffect, useState } from "react";
import Frame from "../../Frame";
import Question from "../../Question";
import Range from "../../Range";
import Input from "../../Input";
import Select from "../../Select";
import fetchData from "../../../services/fetchData";

const FormatNumber = (number, e) => {
  const inputType = e.nativeEvent.inputType;
  if (inputType === "deleteContentBackward")
    number = parseFloat(number.replace(/\s/g, "")).toString().slice(0, -1);
  console.log(number);
  number = number.replace(/\s/g, "");
  number = number.replace(/\./g, ",");
  number = number.replace(/[^0-9]/g, "");
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €";
};

const TimeRate = () => {
  const [job, setJob] = useState({});
  const [business, setBusiness] = useState({});

  useEffect(() => {
    fetchData({
      endpoint: `/business`,
      saveIn: "businessData",
    }).then((response) => {
      if (response?.operation === "success") {
        setBusiness(response);
      }
    });
  }, []);

  useEffect(() => {
    const fetchJob = async () => {
      if (Object.values(business).length > 0) {
        const currentBusiness =
          business.typeof === "object"
            ? business.data
            : business.data?.find((b) => b.user_id === business.userId);
        if (currentBusiness) {
          const response = await fetchData({
            endpoint: `/jobs`,
            saveIn: "jobsKnowledge",
          });
          if (response?.operation === "success") {
            const job = response.data.find(
              (job) => job.id === currentBusiness.job_id
            );
            setJob(job);
          }
        }
      }
    };
    fetchJob();
  }, [business]);

  return (
    <Frame type={3} className='center'>
      <Question>
        Part de travail à distance pendant vos missions de {job.label} ?
      </Question>

      <Range
        className='rangeOrange'
        value={40}
        name='remote'
        saveIn='form_step2'
      />
      <Question>Chiffre d'affaires</Question>
      <p>
        Réalisé sur les 365 derniers jours dans ce métier et cette entreprise
      </p>
      <Input
        placeholder='30 000 €'
        onChange={FormatNumber}
        name='turnover'
        saveIn='form_step2'
      />
      <Question>Nombre de missions</Question>
      <p>
        Réalisées sur les 365 derniers jours dans ce métier et cette entreprise
        1 mission = 1 contrat signé, réalisé ou en cours{" "}
      </p>
      <Select
        placeholder='20 missions'
        name='nbMissions'
        saveIn='form_step2'
        options={[
          { label: "1 mission", value: "1" },
          { label: "5 missions", value: "5" },
          { label: "10 missions", value: "10" },
          { label: "15 missions", value: "15" },
          { label: "20 missions", value: "20" },
          { label: "25 missions", value: "25" },
          { label: "30 missions", value: "30" },
          { label: "35 missions", value: "35" },
          { label: "40 missions", value: "40" },
          { label: "45 missions", value: "45" },
          { label: "50 missions", value: "50" },
        ]}
      />
    </Frame>
  );
};

export default TimeRate;
