import React, { useEffect, useState } from "react";
import fetchData from "../../../services/fetchData";
import Button from "../../Button";
const FetchSiren = ({ handleSetStep }) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchData({
          endpoint: "/companies/siret/" + localStorage.getItem("siretCompany"),
          method: "GET",
          saveIn: "companyData",
        });
        if (response?.operation === "success") {
          handleSetStep(4);
        } else {
          setError(response.status);
          handleSetStep(2);
        }
      } catch (error) {
        Notification.error(`Une erreur est survenue : ${error.message}`);
        setError(error.message);
        handleSetStep(2);
      }
    })();
  }, [handleSetStep]);

  return error ? (
    <div className='center'>
      <p>Erreur lors de la récupération des données de votre entreprise.</p>
      <p>
        <br />
      </p>
      <div className='center'>
        <Button onClick={() => window.location.reload()}>Retour</Button>
      </div>
    </div>
  ) : null;
};

export default FetchSiren;
