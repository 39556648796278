import { useState, useEffect, useRef } from "react";
import Question from "./Question";
import Switch from "./Switch";
import fetchData from "../services/fetchData";

const QuestionSwitch = ({ question, name, saveIn, defaultValue = null }) => {
  const [clicked, setClicked] = useState(() => {
    try {
      const prevData = JSON.parse(localStorage.getItem(saveIn) || "{}");
      if (prevData && prevData[name]) {
        return prevData[name];
      }
    } catch (error) {
      console.error(error);
    }
    return defaultValue;
  });

  const [answers, setAnswers] = useState([]);
  useEffect(() => {
    setClicked(
      answers?.filter(
        (answer) => answer.label === "Vrai" && answer.question_id === name
      )[0]?.id || true
    );
  }, [answers, name]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    fetchData({
      endpoint: `/answers`,
      saveIn: "answersKnowledge",
    })
      .then((data) => {
        setAnswers(data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (saveIn && name) {
      const storedData = JSON.parse(localStorage.getItem(saveIn) || "{}");
      localStorage.setItem(
        saveIn,
        JSON.stringify({
          ...storedData,
          [name]: clicked,
        })
      );
    }
  }, [clicked, saveIn, name]);

  const handleClick = (state) => {
    setClicked(state);
  };

  return (
    <div>
      <Question className={"questionInSwitch"}>{question}</Question>
      <div className='trueFalseContainer'>
        <p>Vrai</p>
        <Switch
          name={name}
          handleChange={(state) => {
            handleClick(state);
          }}
          true_id={
            answers?.filter(
              (answer) => answer.label === "Vrai" && answer.question_id === name
            )[0]?.id
          }
          false_id={
            answers?.filter(
              (answer) => answer.label === "Faux" && answer.question_id === name
            )[0]?.id
          }
          initialValue={clicked}
        />
        <p>Faux</p>
      </div>
    </div>
  );
};

export default QuestionSwitch;
