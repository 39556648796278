import Frame from "../../Frame";
import RangeCommunication from "../../RangeCommunication";

const Communication1 = ({ handleNextStep }) => {
  return (
    <Frame type={3} className='center'>
      <RangeCommunication
        label='Je regarde facilement la personne qui me parle ou à qui je parle'
        name='regard'
        saveIn='form_step3'
      />
      <RangeCommunication
        label='Je parle assez fort pour être entendu'
        name='entendu'
        saveIn='form_step3'
      />
      <RangeCommunication
        label='Je parle assez distinctement pour être compris'
        name='articuler'
        saveIn='form_step3'
      />
    </Frame>
  );
};

export default Communication1;
