import React from "react";
import Frame from "../../Frame";
import Title from "../../Title";
import UserProfil from "../../partials/UserProfil";

const FinishInit = () => {
  const userDataLS = JSON.parse(localStorage.getItem("userData"));
  const userData =
    userDataLS.typeof === "object"
      ? userDataLS?.data
      : userDataLS?.data?.filter((item) => item.id === userDataLS.userId)[0];
  return (
    <Frame type={3}>
      <p>
        <br />
      </p>
      <UserProfil />
      <Title className={"darkBlue center"}>
        {userData.name}, la phase d’Initialisation{" "}
        <span className='green'>est terminée !</span>
      </Title>
    </Frame>
  );
};

export default FinishInit;
