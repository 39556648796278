import React from "react";
import Frame from "../../Frame";
import Title from "../../Title";
import Loader from "../../Loader";
import Button from "../../Button";

const StartScreen = ({ handleNextStep }) => {
  return (
    <Frame type={3} className='center'>
      <div className='center'>
        <Loader />
      </div>
      <Title>
        Étape 4<br />
        Stratégie de TJM
      </Title>

      <p>
        Cette étape est la plus courte mais aussi la plus importante. Elle a
        pour objectif de définir, une stratégie à laquelle seront liés votre
        communication de profil et votre TJM afin de donner de la cohérence au
        tout et de la légitimité auprès de vos clients.
      </p>
      <p>
        {" "}
        Greadn vous fournit aussi en résultats la liste de vos potentiels et des
        atouts sur lesquels vous appuyer pour argumenter votre TJM dans le cas
        où cela vous serait demandé.
      </p>
      <p>
        <strong>
          Les questions posées dans cette étape concernent votre personnalité.
        </strong>
      </p>
      <div className='center'>
        <Button
          value='Commencer &gt;'
          onClick={handleNextStep}
          className='center'
        />
      </div>
    </Frame>
  );
};

export default StartScreen;
