import Frame from "../../Frame";
import RangeCommunication from "../../RangeCommunication";
import Question from "../../Question";

const Communication = () => {
  return (
    <Frame type={3} className='center'>
      <Question>Votre mode de communication</Question>
      <p>
        Les questions suivantes concernent la façon dont vous communiquez.
        Greadn utilise la méthode de Cungi et Rey, validée scientifiquement.
      </p>
      <p>
        Indiquez à quel point chacune des affirmations suivantes vous correspond
        sur une échelle de 1 à 8.
      </p>
      <RangeCommunication
        label="Je me sens à l'aise dans les situations relationnelles"
        saveIn='form_step3'
        name='communication'
      />
    </Frame>
  );
};

export default Communication;
