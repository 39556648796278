import React, { useEffect, useState } from "react";
import Input from "./Input";

const Address = ({ address, postalCode, city, saveIn = null, name = null }) => {
  const [addressValue, setAddressValue] = useState(address);
  const [postalCodeValue, setPostalCodeValue] = useState(postalCode);
  const [cityValue, setCityValue] = useState(city);

  useEffect(() => {
    setAddressValue(address);
    setPostalCodeValue(postalCode);
    setCityValue(city);
  }, [address, postalCode, city]);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem(saveIn));
    if (stored && stored[name]) {
      const { address, postalCode, city } = stored[name];

      setAddressValue(address);
      setPostalCodeValue(postalCode);
      setCityValue(city);
    }
  }, [name, saveIn]);

  useEffect(() => {
    if (saveIn && name) {
      localStorage.setItem(
        saveIn,
        JSON.stringify({
          ...JSON.parse(localStorage.getItem(saveIn)),
          [name]: {
            address: addressValue,
            postalCode: postalCodeValue,
            city: cityValue,
          },
        })
      );
    }
  }, [addressValue, postalCodeValue, cityValue, saveIn, name]);

  return (
    <>
      <div className='addressComponent'>
        <Input
          placeholder='Adresse'
          onChange={setAddressValue}
          value={addressValue}
        />
        <Input
          placeholder='Code postal'
          onChange={setPostalCodeValue}
          value={postalCodeValue}
        />
        <Input placeholder='Ville' onChange={setCityValue} value={cityValue} />
      </div>
      <input
        type='hidden'
        value={JSON.stringify({ addressValue, postalCode, city })}
      />
    </>
  );
};

export default Address;
