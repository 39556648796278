import React from "react";
import Frame from "../../Frame";
import Title from "../../Title";
import Loader from "../../Loader";
import Button from "../../Button";

const StartScreen = ({ handleNextStep }) => {
  return (
    <Frame type={3} className='center'>
      <div className='center'>
        <Loader />
      </div>
      <Title>
        Étape 1<br />
        Tarif Journalier Seuil
      </Title>
      <p>
        D’autre-part, si vous fixez votre Tarif Journalier Moyen en-dessous de
        ce seuil, vous risquez une perte de rémunération ET de missions
        importantes, laissant ainsi d’autres Freelances récolter les fruits d’un
        TJM juste à votre place.
      </p>
      <p>
        {" "}
        Enfin, Greadn ne tient pas compte de l’imposition sur salaire ou
        bénéfices. En effet, elle dépend de la situation personnelle et
        patrimoniale. Greadn déconseille d’ailleurs d’optimiser son tarif en
        fonction de son imposition, car il serait difficile de justifier auprès
        des clients une modification tarifaire par une modification de situation
        patrimoniale ou personnelle.{" "}
      </p>
      <p>
        <strong>
          Les questions posées dans cette étape sont administratives, fiscales
          et financières.
        </strong>
      </p>
      <div className='center'>
        <Button
          value='Commencer &gt;'
          onClick={handleNextStep}
          className='center'
        />
      </div>
    </Frame>
  );
};

export default StartScreen;
