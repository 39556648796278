import Frame from "../../Frame";
import Question from "../../Question";
import RangeRate from "../../RangeRate";

const StartScreen = ({ handleNextStep }) => {
  const company = JSON.parse(localStorage.getItem("companyData"));
  return (
    <Frame type={3} className='center'>
      <Question>Frais d'entreprise</Question>
      <p>Frais liés à tous les métiers exercés dans cette entreprise.</p>
      <h2 style={{ fontSize: "20px" }}>Frais variable</h2>
      <p>en % du chiffre d'affaire</p>

      {company?.company_type_id === 4 && (
        <RangeRate
          label='Portage salarial'
          min={0}
          max={15}
          value={5}
          name='portage_salarial'
          saveIn='form_step1'
        />
      )}
      <RangeRate
        label='Prévoyance santé'
        min={0}
        max={15}
        value={5}
        name='health_care'
        saveIn='form_step1'
      />

      <RangeRate
        label='Assurance chômage'
        min={0}
        max={15}
        value={5}
        name='unemployment_insurance'
        saveIn='form_step1'
      />
    </Frame>
  );
};

export default StartScreen;
