import Frame from "../../Frame";
import Badge from "../../Badge";
import Title from "../../Title";

const FinishBadge = () => {
  const userDataLS = JSON.parse(localStorage.getItem("userData"));
  const userData =
    userDataLS.typeof === "object"
      ? userDataLS?.data
      : userDataLS?.data?.filter((item) => item.id === userDataLS.userId)[0];
  return (
    <Frame type={3} className={"center"}>
      <Badge step='4' className={"big center"}></Badge>
      <Title className={"darkBlue center"}>
        {userData.name}{" "}
        <span className='green'>votre TJM de plafond est enregistré !</span>
      </Title>
      <p>
        Quel bel arbuste ! Vous manquent juste les principaux ingrédients pour
        le fertiliser :<br />
        <br />
        une stratégie tarifaire et sa communication.{" "}
      </p>
    </Frame>
  );
};

export default FinishBadge;
