import React, { useState, useEffect } from "react";

const RangeAC = ({
  min = 0,
  max = 100,
  step = 1,
  value = 40,
  label,
  onChange,
  name = null,
  saveIn = null,
}) => {
  const [rangeValue, setRangeValue] = useState(() => {
    try {
      const prevData = JSON.parse(localStorage.getItem(saveIn) || "{}");
      if (prevData && (prevData[name] || prevData[name] === 0)) {
        return prevData[name] > max ? max : prevData[name];
      }
    } catch (error) {
      console.error(error);
    }
    return value > max ? max : value;
  });
  const [leftValue, setLeftValue] = useState(
    ((rangeValue - min) / (max - min)) * 100
  );

  useEffect(() => {
    if (saveIn && name) {
      let prevData = JSON.parse(localStorage.getItem(saveIn) || "[]");
      if (typeof prevData !== "object") {
        prevData = [];
      }

      localStorage.setItem(
        saveIn,
        JSON.stringify({
          ...prevData,
          [name]: rangeValue,
        })
      );
    }
  }, [saveIn, name, rangeValue]);

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value);
    setRangeValue(newValue);
    setLeftValue(((newValue - min) / (max - min)) * 100);
    onChange && onChange(newValue);
  };

  return (
    <div className='secondaryBlock rangeRateComponent'>
      <div className='labelContainer' style={{ justifyContent: "center" }}>
        <p className='secondaryFont'>
          <strong>{label}</strong>
        </p>
      </div>

      <div className='rangeComponent rangeGreen'>
        <input
          type='range'
          min={min}
          max={max}
          step={step}
          value={rangeValue}
          onChange={handleChange}
          className='range-input'
        />

        <div className='range-track' style={{ width: `${leftValue}%` }}></div>
        <div className='range-labels'>
          <span className='range-min'>Absente</span>
          <span className='range-max'>Cruciale</span>
        </div>
        <div className='bg'></div>
      </div>
    </div>
  );
};

export default RangeAC;
