import React, { useEffect, useState } from "react";
import Frame from "../../Frame";
import Question from "../../Question";
import CalendarMonth from "../../CalendarMonth";
import Input from "../../Input";
import fetchData from "../../../services/fetchData";

const SinceFreelance = () => {
  const [job, setJob] = useState({});
  const [business, setBusiness] = useState({});

  useEffect(() => {
    fetchData({
      endpoint: `/business`,
      saveIn: "businessData",
    }).then((response) => {
      if (response?.operation === "success") {
        setBusiness(response);
      }
    });
  }, []);

  useEffect(() => {
    const fetchJob = async () => {
      if (Object.values(business).length > 0) {
        const currentBusiness =
          business.typeof === "object"
            ? business.data
            : business.data?.find((b) => b.user_id === business.userId);
        if (currentBusiness) {
          const response = await fetchData({
            endpoint: `/jobs`,
            saveIn: "jobsKnowledge",
          });
          if (response?.operation === "success") {
            const job = response.data.find(
              (job) => job.id === currentBusiness.job_id
            );
            setJob(job);
          }
        }
      }
    };
    fetchJob();
  }, [business]);

  return (
    <Frame type={3} className='center'>
      <Question>Quand avez-vous commencé à travailler ?</Question>
      <p>Ne pas inclure les périodes de stages et d’études, ni l’alternance.</p>
      <CalendarMonth name='work_since' saveIn='form_step2' />
      <Question>Depuis quand êtes-vous {job?.label} ? </Question>
      <Input
        placeholder={"jj - mm - aaaa"}
        className=''
        type='date'
        name='job_since'
        saveIn='form_step2'
      />
    </Frame>
  );
};

export default SinceFreelance;
