import React, { useState, useEffect } from "react";

const RangeRadian = ({
  initialValue = 100,
  unit = "",
  name = null,
  saveKey = null,
  saveIn = null,
}) => {
  const [angle, setAngle] = useState(() => {
    try {
      const prevData = JSON.parse(localStorage.getItem(saveIn) || "{}");
      if (
        prevData &&
        (prevData[`angle${name}`] || prevData[`angle${name}`] === 0)
      ) {
        return prevData[`angle${name}`] ? prevData[`angle${name}`] : 360;
      }
    } catch (error) {
      console.error(error);
    }
    return 360;
  });
  const [value, setValue] = useState(() => {
    try {
      const prevData = JSON.parse(localStorage.getItem(saveIn) || "{}");
      if (prevData && (prevData[name] || prevData[name] === 0)) {
        return prevData[name] ? prevData[name] : initialValue;
      }
    } catch (error) {
      console.error(error);
    }
    return initialValue;
  });

  const handleMouseDown = (e) => {
    e.preventDefault();
    const startPosition = e.clientX;
    const startAngle = angle;

    const handleMouseMove = (e) => {
      const currentPosition = e.clientX;
      const diff = currentPosition - startPosition;
      const newAngle = startAngle + diff;
      setAngle(newAngle);

      // Calculate the value based on the angle
      let newValue = (-newAngle / 360) * initialValue;
      if (newValue > 0) newValue = 0;
      setValue(Math.abs(Math.round(newValue)));

      if (saveKey)
        localStorage.setItem(saveKey, Math.abs(Math.round(newValue)));
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  useEffect(() => {
    console.log("angle", angle);
    if (saveIn && name) {
      localStorage.setItem(
        saveIn,
        JSON.stringify({
          ...JSON.parse(localStorage.getItem(saveIn)),
          [name]: value,
          [`angle${name}`]: angle,
        })
      );
    }
  }, [value, angle, saveIn, name]);

  return (
    <div className='rangeRadianComponent'>
      <div className='border'></div>
      <div
        className='cursor'
        style={{ transform: `rotate(${angle}deg)` }}
        onMouseDown={handleMouseDown}
      >
        <div
          style={{ transform: `translateY(20px) rotate(-${angle}deg)` }}
          onMouseDown={handleMouseDown}
        >
          <img
            src='https://cdn.greadn.com/assets/images/range-button.svg'
            alt=''
            className='buttonRange'
          />
        </div>
      </div>
      <div className='displayValue'>
        {value}
        {unit}
      </div>
    </div>
  );
};

export default RangeRadian;
