import React from "react";
import Frame from "../../Frame";
import Title from "../../Title";

const Result = () => {
  return (
    <Frame type={3}>
      <Title className={"darkBlue center"}>
        Vous êtes sur le point d’entamer{" "}
        <span className='green'>votre premier parcours !</span>
      </Title>
      <div className='primaryBlock' style={{ padding: "20px 30px" }}>
        <p className='center secondaryFont'>
          <strong>
            Vos connaissances sur le TJM se situent{" "}
            <span className='orange'>un peu en deçà</span> de la majorité des
            Freelances dans votre métier.
          </strong>
        </p>
      </div>
      <p>Un parcours suffira pour régler ça.</p>
      <p>
        <strong>
          Greadn va vous dévoiler les meilleures techniques de tarification et
          vous guider pour construire un TJM juste..
        </strong>
      </p>
    </Frame>
  );
};

export default Result;
