import React, { useEffect, useState } from "react";
import Frame from "../../Frame";
import Question from "../../Question";
import Select from "../../Select";
import Checkbox from "../../Checkbox";
import Label from "../../Label";
import InputSiret from "../../InputSiret";
import fetchData from "../../../services/fetchData";

var config = {
  codePays: "FR",
  // Deprecated and should mode to https://data.inpi.fr/
  inseeApiBase: "https://entreprise.data.gouv.fr/api/sirene/v3/",
  inseeApiSearchSiren: "unites_legales/",
  inseeApiSearchSiret: "etablissements/",
  longCode: true,
};

function getTvaKeyFromSiren(number) {
  var k = (12 + 3 * (parseInt(number) % 97)) % 97;
  var key = k < 10 ? "0" + k : "" + k;
  return key;
}

function cleanSiret(number) {
  if (number === null || number === undefined) {
    return "";
  }
  var numero = number.toString();
  numero = numero.replace(/\s/g, "");
  return numero;
}

function convertSiren2Tva(number) {
  if (number === null && number === undefined) {
    return "";
  }
  var numero = cleanSiret(number);
  numero = numero.substring(0, 9);
  var codeP = config.codePays;
  var key = getTvaKeyFromSiren(numero);
  if (config.longCode === true) {
    return (
      codeP +
      key +
      "" +
      numero.substring(0, 3) +
      "" +
      numero.substring(3, 6) +
      "" +
      numero.substring(6, 9)
    );
  } else {
    return codeP + key + numero;
  }
}

const CompanyDetails = () => {
  const [company, setCompany] = useState({});
  const [business, setBusiness] = useState({});

  useEffect(() => {
    fetchData({
      endpoint: `/business`,
      saveIn: "businessData",
    }).then((response) => {
      if (response?.operation === "success") {
        setBusiness(response);
      }
    });
  }, []);

  useEffect(() => {
    const fetchJob = async () => {
      if (Object.values(business).length > 0) {
        const currentBusiness =
          business.typeof === "object"
            ? business.data
            : business.data?.find((b) => b.user_id === business.userId);
        if (currentBusiness) {
          const response = await fetchData({
            endpoint: `/companies`,
            saveIn: "companiesData",
          });
          if (response?.operation === "success") {
            const company = response.data.find(
              (company) => company.id === currentBusiness.company_id
            );
            setCompany(company);
          }
        }
      }
    };
    fetchJob();
  }, [business]);
  return (
    <Frame type={3} className='center'>
      <Question>Forme juridique d’entreprise</Question>
      <p>Greadn tient compte des charges liées au régime fiscal.</p>
      <Select
        name='CompanyType'
        saveIn='form_step1'
        options={[
          { value: "micro", label: "Entrepreneur Individuel (dont micro)" },
          { value: "portage", label: "Portage salarial" },
          { value: "eurl", label: "EURL" },
          { value: "sasu", label: "SASU" },
        ]}
      ></Select>
      <Question>Régime fiscal d’imposition</Question>
      <p>
        Votre régime d’imposition est choisi à l’ouverture de votre entreprise
        puis évolue en fonction de votre chiffre d’affaires.
      </p>
      <Select
        name='CompanyRegime'
        saveIn='form_step1'
        options={[
          { value: "micro", label: "Micro-entreprise" },
          { value: "simple", label: "Régime simplifié" },
          { value: "reel", label: "Régime réel" },
        ]}
      ></Select>
      <Checkbox label='En franchise de TVA' />
      <Label>N°TVA intracommunautaire</Label>
      <InputSiret
        name='n_tva'
        maxLength={13}
        value={convertSiren2Tva(company.siret)}
      ></InputSiret>
    </Frame>
  );
};

export default CompanyDetails;
