import React, { useEffect, useState } from "react";

const RadioGroup = ({
  className = "",
  name = "",
  items = [],
  onChange = () => {},
  saveIn = null,
  valueProps = "",
}) => {
  const [value, setValue] = useState(() => {
    try {
      const prevData = JSON.parse(localStorage.getItem(saveIn) || "{}");
      if (prevData && (prevData[name] || prevData[name] === 0)) {
        return prevData[name];
      }
    } catch (error) {
      console.error(error);
    }
    return valueProps;
  });

  useEffect(() => {
    if (saveIn && name) {
      localStorage.setItem(
        saveIn,
        JSON.stringify({
          ...JSON.parse(localStorage.getItem(saveIn)),
          [name]: value,
        })
      );
    }
  }, [saveIn, name, value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <>
      {items.map((item) => (
        <div className={`radioCheckComponent ${className}`}>
          <input
            type='radio'
            onChange={(e) => handleChange(e.target.value)}
            name={name}
            key={name + item.value}
            id={name + item.value}
            checked={value === item.value}
            value={item.value}
          />
          <div
            className={`customRadioCheck ${
              value === item.value ? "checked" : ""
            }`}
            onClick={() => handleChange(item.value)}
          ></div>
          <label
            htmlFor={name + item.value}
            onClick={() => handleChange(item.value)}
          >
            {item.label}
          </label>
        </div>
      ))}
    </>
  );
};

export default RadioGroup;
