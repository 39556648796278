import React from "react";
import Frame from "../../Frame";
import Title from "../../Title";
import RangeRadian from "../../RangeRadian";

const SetAdrInitial = () => {
  const job_id = JSON.parse(localStorage.getItem("businessData"))?.data.job_id;
  const jobsKnowledge = JSON.parse(localStorage.getItem("jobsKnowledge"))?.data;
  const job_label = jobsKnowledge.find((item) => item.id === job_id)?.label;

  return (
    <Frame type={2} className='center'>
      <Title className='darkBlue'>
        Aujourd’hui, quel est votre Tarif Journalier Moyen en
      </Title>
      <p className='insideShadow'>{job_label}</p>
      <p>
        <strong>
          Cette information est demandée une seule fois lors du premier parcours
          dans un métier.
        </strong>
      </p>
      <p>
        <strong> Elle ne peut plus être modifiée par la suite.</strong>{" "}
      </p>
      <p>
        Votre TJM actuel n’a aucune incidence sur les tarifs déterminés par
        Greadn. Il est utilisé uniquement pour estimer l’impact de Greadn, sur
        votre activité.
      </p>
      <p>
        À la fin de votre parcours, Greadn vous propose d’actualiser votre TJM
        selon vos résultats. Ce TJM actualisé sera utilisé lors du parcours
        suivant dans ce même métier.
      </p>
      <p>
        <br />
      </p>
      <RangeRadian unit='€' saveKey='adr_initial'></RangeRadian>
    </Frame>
  );
};

export default SetAdrInitial;
