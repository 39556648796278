const Notification = {
  success: (message) => {
    alert(message);
  },
  error: (message) => {
    alert(message);
  },
};

export default Notification;
