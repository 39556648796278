import React from "react";
import Frame from "../../Frame";
import Title from "../../Title";
import BigButton from "../../BigButton";

const Lancer = () => {
  return (
    <div className='secondaryBlock'>
      <div className='center'>
        <img
          src={
            "https://cdn.greadn.com/assets/images/logo-greadn-freelance-lancer.svg"
          }
          alt=''
          className='blockLogo'
        />
      </div>
      <Frame type={2} className='center'>
        <Title>Formule bientôt Freelance</Title>
        <p>
          Faire prospérer votre prochaine activité d’indépendant, c’est faire
          fructifier un verger de clients et de prospects.
        </p>
        <p>Si le TJM est trop élevé ou trop bas le verger dépérit.</p>
        <p>
          Autour de vous, des Freelances s’affairent sans forcément récolter de
          fruits.
        </p>
        <p>
          Vous pourriez copier le TJM de ceux qui récoltent le mieux. Mais en y
          regardant de plus près, chaque arbre de cet immense verger a ses
          propres besoins et votre future parcelle est différente.
        </p>
        <h2>Comment dans ces conditions connaître votre TJM juste ?</h2>
        <p>
          Un véritable casse-tête. Il faut tenir compte de tellement de
          choses...
        </p>
        <h2>Greadn détermine pour vous</h2>
        <ul className='center'>
          <li>Votre Tarif Journalier</li>
          <li>Moyen 100% personnalisé</li>
          <li>Votre Tarif Seuil</li>
          <li>Votre Tarif Plafond</li>
          <li>La fourchette de TJM de marché Votre stratégie tarifaire</li>
          <li>Vos potentiels d’amélioration</li>
          <li>Vos atouts et la manière de communiquer dessus</li>
        </ul>
        <p>
          Bref, tout ce dont vous avez besoin pour bien démarrer votre activité.
        </p>
        <BigButton content='Démarrer' />
        <p>
          <br />
        </p>
      </Frame>
    </div>
  );
};

export default Lancer;
