import React, { useEffect, useState } from "react";
import Frame from "../../Frame";
import Question from "../../Question";
import RangeRadian from "../../RangeRadian";
import BoxAlert from "../../BoxAlert";
import fetchData from "../../../services/fetchData";

const Request = () => {
  const [job, setJob] = useState({});
  const [business, setBusiness] = useState({});

  useEffect(() => {
    fetchData({
      endpoint: `/business`,
      saveIn: "businessData",
    }).then((response) => {
      if (response?.operation === "success") {
        setBusiness(response);
      }
    });
  }, []);

  useEffect(() => {
    const fetchJob = async () => {
      if (Object.values(business).length > 0) {
        const currentBusiness =
          business.typeof === "object"
            ? business.data
            : business.data?.find((b) => b.user_id === business.userId);
        if (currentBusiness) {
          const response = await fetchData({
            endpoint: `/jobs`,
            saveIn: "jobsKnowledge",
          });
          if (response?.operation === "success") {
            const job = response.data.find(
              (job) => job.id === currentBusiness.job_id
            );
            setJob(job);
          }
        }
      }
    };
    fetchJob();
  }, [business]);

  return (
    <Frame type={3} className={"center"}>
      <Question>La demande en {job.label}</Question>
      <p className='secondaryFont darkBlue'>
        En moyenne et par mois, à combien d’offres de missions répondez-vous ?
        <br />
        <br />
      </p>
      <RangeRadian
        name='offers_answered'
        saveIn='form_step2'
        initialValue={50}
      />
      <p>
        <br />
      </p>
      <BoxAlert>
        Vérifiez bien que les informations rentrées sont correctes car après
        avoir cliqué sur suivant vous ne pourrez plus les modifier dans ce
        parcours.
      </BoxAlert>
    </Frame>
  );
};

export default Request;
