import Frame from "../../Frame";
import Question from "../../Question";
import RangeDay from "../../RangeDay";
import BoxAlert from "../../BoxAlert";

const TimeFree = ({ handleNextStep }) => {
  return (
    <Frame type={3} className='center'>
      <Question>Temps non facturé</Question>
      <p>
        Jours d’activité que vous ne facturez pas à vos clients pour tous les
        métiers exercés dans cette entreprise.
        <br /> Cliquez sur le montant pour le modifier.
      </p>
      <p>
        <em>[components manquant]</em>
      </p>
      <h2 style={{ fontSize: "20px" }}>Provision de jours</h2>
      <p>
        Conseil : Conservez en provision pour les aléas (maladie...) au moins 10
        jours/an à temps plein
      </p>

      <RangeDay
        min={0}
        max={20}
        value={10}
        name='timeFree'
        saveIn='form_step1'
      ></RangeDay>
      <BoxAlert>
        Vérifiez bien que les informations rentrées sont correctes car après
        avoir cliqué sur suivant vous ne pourrez plus les modifier dans ce
        parcours.
      </BoxAlert>
    </Frame>
  );
};

export default TimeFree;
