import LayoutPrimary from "../components/partials/LayoutPrimary";
import Title from "../components/Title";
import Frame from "../components/Frame";

const PaiementsFactures = () => {
  return (
    <LayoutPrimary>
      <Frame type={3} className='center'>
        <Title title='Paiements & factures' />
        <div className='center'>
          <img
            src='https://cdn.greadn.com/assets/images/logo-warning.svg'
            alt=''
          />
          <img src='https://cdn.greadn.com/assets/images/text.svg' alt='' />
        </div>
      </Frame>
    </LayoutPrimary>
  );
};

export default PaiementsFactures;
