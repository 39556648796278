import React, { useState, useEffect } from "react";
import LetterSeparatedInput from "./LetterSeparatedInput";
import BoxAlert from "./BoxAlert";

const InputSiret = ({ maxLength = 14, name, value = "", onChange = null }) => {
  const [inputValue, setInputValue] = useState(value);
  const [messageAlert, setMessageAlert] = useState("");
  const [maxWidth, setMaxWidth] = useState("100%");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    // Calculate offset width of .letter-span
    const letterSpanWidth = 24;

    // Calculate maximum width based on maxLength prop and letterSpanWidth
    const maxWidthCalc = letterSpanWidth * maxLength;

    // Set maximum width
    setMaxWidth(`${maxWidthCalc}px`);
  }, [maxLength]);

  const handleInputChange = (e) => {
    if (e.target.value.length > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength - 1);
    }

    onChange && setInputValue(onChange(e.target.value, e));
    !onChange && setInputValue(e.target.value);

    const regex = /^[0-9]*$/;
    if (e.target.value.length === 0) {
      setMessageAlert("");
    } else if (e.target.value.length < maxLength) {
      setMessageAlert(
        "SIRET incomplet, merci de rentrer " + maxLength + " chiffres"
      );
    } else if (!regex.test(e.target.value)) {
      setMessageAlert(
        "SIRET invalide, merci de rentrer " + maxLength + " chiffres"
      );
    } else {
      setMessageAlert("");
      // set in localStorage the value
      localStorage.setItem(name, e.target.value);
    }
  };

  return (
    <div>
      <div className='inputSiretComponent' style={{ maxWidth: maxWidth }}>
        <input
          type='text'
          className='hiddenInput'
          value={inputValue}
          onChange={handleInputChange}
        />
        <LetterSeparatedInput value={inputValue} />
      </div>
      {messageAlert !== "" ? <BoxAlert>{messageAlert}</BoxAlert> : null}
    </div>
  );
};

export default InputSiret;
