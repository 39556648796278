const Badge = ({ label, active = true, step, className = "" }) => {
  return (
    <div className={`badge ${active ? "active" : ""} ${className}`}>
      <div className='icon'>
        {step ? (
          <img
            src={`https://cdn.greadn.com/assets/images/badge${step}.svg`}
            alt=''
          />
        ) : null}
      </div>
      <p>{label || ""}</p>
    </div>
  );
};

export default Badge;
