import React from "react";
import Button from "./Button";
import Notification from "../services/Notification";

function Step({
  children,
  handleNextStep,
  handlePreviousStep,
  handleSetStep,
  displayButton,
  className = "",
  beforeNextStep = null,
}) {
  const childrenWithProps = React.Children.toArray(
    React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          handleNextStep,
          handlePreviousStep,
          handleSetStep,
        });
      }
      return child;
    })
  );

  const clickNextStep = () => {
    if (beforeNextStep) {
      beforeNextStep().then((response) => {
        const global = {
          operation: "success",
          errorMessage: "",
        };

        if (Array.isArray(response)) {
          response.forEach((item) => {
            if (typeof item === "object" && item.operation !== "success") {
              global.operation = "error";
              global.errorMessage = item.errorMessage;
            }
          });
        } else if (typeof response === "object") {
          if (response?.operation !== "success") {
            global.operation = "error";
            global.errorMessage = response.errorMessage;
          }
        }

        if (global.operation === "success") {
          handleNextStep();
        } else {
          Notification.error(response.errorMessage);
        }
      });
    } else if (handleNextStep) {
      handleNextStep();
    }
  };

  return (
    <div className={`frame1 ${className}`}>
      {childrenWithProps}

      {displayButton === "next" && (
        <div className='navButtonContainer'>
          <Button onClick={clickNextStep} className='center'>
            Suivant &gt;
          </Button>
        </div>
      )}
      {displayButton === "both" && (
        <div className='navButtonContainer'>
          <Button onClick={handlePreviousStep} className='center'>
            &lt;
          </Button>
          <Button onClick={clickNextStep} className='center'>
            &gt;
          </Button>
        </div>
      )}
    </div>
  );
}

Step.defaultProps = {
  className: "",
  displayButton: "next",
};

export default Step;
