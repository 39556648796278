import Frame from "../../Frame";
import UserProfil from "../../partials/UserProfil";
import Title from "../../Title";
import Button from "../../Button";

const FinishProfil = ({ handleNextStep }) => {
  const userDataLS = JSON.parse(localStorage.getItem("userData"));
  const userData =
    userDataLS.typeof === "object"
      ? userDataLS?.data
      : userDataLS?.data?.filter((item) => item.id === userDataLS.userId)[0];
  return (
    <Frame type={3} className={"center"}>
      <p>
        <br />
      </p>
      <UserProfil />
      <Title className={"darkBlue center"}>
        {userData.name}, la phase de calcul du TJPlafond{" "}
        <span className='green'>est terminée !</span>
      </Title>
      <div className='center'>
        <Button onClick={handleNextStep}>Obtenir mon badge &gt;</Button>
      </div>
    </Frame>
  );
};

export default FinishProfil;
