import React from "react";
import Frame from "../../Frame";
import Question from "../../Question";

const AdviceStrat = () => {
  const leftValue = 70;
  return (
    <Frame type={3} className='center'>
      <Question>Stratégie conseillée</Question>
      <div className='secondaryBlock'>
        Alignement = s’aligner sur le prix du marché
      </div>
      <p>
        Si vous souhaitez choisir une autre stratégie que celle proposée,
        modifiez la position du curseur ci-dessous.
      </p>
      <div className='secondaryBlock rangeRateComponent '>
        <div
          className='labelContainer'
          style={{
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <p className='secondaryFont'>
            <strong>Stratégie</strong>
          </p>
          <div className='secondaryBlock' style={{ padding: `7px 8px 5px` }}>
            <p className='secondaryFont'>Alignement</p>
          </div>
        </div>

        <div className='rangeComponent rangeYellow'>
          <input
            type='range'
            min='0'
            max='10'
            value='7'
            className='range-input'
            style={{
              pointerEvents: "none",
            }}
          />

          <div className='range-track' style={{ width: `${leftValue}%` }}></div>
          <div className='range-labels'>
            <span className='range-min'>TJM bas</span>
            <span className='range-max'>TJM élevé</span>
          </div>
          <div className='bg'></div>
          <p className='center'>
            La stratégie d’alignement consiste{" "}
            <span className='green'>à pratiquer le tarif médian du marché</span>{" "}
            pour votre profil.
          </p>
        </div>
      </div>
    </Frame>
  );
};

export default AdviceStrat;
