import React, { useState, useEffect } from "react";
import Select from "./Select";

const langueScale = (number) => {
  if (number < 5) {
    return "Sans";
  } else if (number < 10) {
    return "A1";
  } else if (number < 20) {
    return "A2";
  } else if (number < 30) {
    return "B1";
  } else if (number < 40) {
    return "B2";
  } else if (number < 50) {
    return "C1";
  } else if (number <= 60) {
    return "Bilingue";
  } else {
    return "";
  }
};

const RangeLangue = ({
  min = 0,
  max = 60,
  step = 1,
  value = 30,
  label,
  onChange,
  name = null,
  saveIn = null,
}) => {
  const [rangeValue, setRangeValue] = useState(() => {
    try {
      const prevData = JSON.parse(localStorage.getItem(saveIn) || "{}");
      if (prevData && (prevData[name] || prevData[name] === 0)) {
        return prevData[name] > max ? max : prevData[name];
      }
    } catch (error) {
      console.error(error);
    }
    return value > max ? max : value;
  });
  const [rangeValueScale, setRangeValueScale] = useState(
    langueScale(rangeValue)
  );
  const [leftValue, setLeftValue] = useState(
    ((rangeValue - min) / (max - min)) * 100
  );

  useEffect(() => {
    if (saveIn && name) {
      let prevData = JSON.parse(localStorage.getItem(saveIn) || "[]");
      if (typeof prevData !== "object") {
        prevData = [];
      }

      localStorage.setItem(
        saveIn,
        JSON.stringify({
          ...prevData,
          [name]: rangeValue,
        })
      );
    }
  }, [saveIn, name, rangeValue]);

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value);
    setRangeValue(newValue);
    setLeftValue(((newValue - min) / (max - min)) * 100);
    setRangeValueScale(langueScale(parseInt(e.target.value)));

    onChange && onChange(newValue);
  };

  return (
    <div className='secondaryBlock rangeRateComponent '>
      <div className='labelContainer'>
        <p className='secondaryFont'>
          <strong>
            {label === "Autre" ? (
              <Select
                name='langue_secondaire'
                saveIn='form_step2'
                options={[
                  { label: "Autre", value: "autre" },
                  { label: "Espagnol", value: "espagnol" },
                  { label: "Allemand", value: "allemand" },
                  { label: "Italien", value: "italien" },
                  { label: "Portugais", value: "Portugais" },
                ]}
                placeholder='Autre'
              />
            ) : (
              label
            )}
          </strong>
        </p>
        <div
          className='secondaryBlock'
          style={{ padding: `7px 18px 5px`, height: "17px" }}
        >
          <p className='secondaryFont'>{rangeValueScale}</p>
        </div>
      </div>

      <div className='rangeComponent rangeOrange'>
        <input
          type='range'
          min={min}
          max={max}
          step={step}
          value={rangeValue}
          onChange={handleChange}
          className='range-input'
        />

        <div className='range-track' style={{ width: `${leftValue}%` }}></div>
        <div className='range-labels'>
          <span className='range-min'>Sans</span>
          <span className='range-max'>Bilingue</span>
        </div>
        <div className='bg'></div>
      </div>
    </div>
  );
};

export default RangeLangue;
