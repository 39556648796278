import React, { useEffect } from "react";
import Frame from "../../Frame";
import Loader from "../../Loader";
import Title from "../../Title";

const LoadScreen = ({ handleNextStep }) => {
  useEffect(() => {
    const timeout854 = setTimeout(() => {
      handleNextStep();
    }, 1000);

    // Clear the timeout when the component unmounts or when the dependency array changes
    return () => clearTimeout(timeout854);
  }, [handleNextStep]); // Empty dependency array ensures this effect runs only once after initial render

  return (
    <Frame type={3} className='center'>
      <div className='center'>
        <Loader animate={true} />
      </div>

      <Title>Initialisation de Greadn</Title>
      <p>
        <strong>
          Chaque étape qui va suivre vous apportera des informations utiles, et
          toutes les étapes sont nécessaires à la construction{" "}
          <span className='green'>d’un TJM juste...</span>
        </strong>
      </p>
    </Frame>
  );
};

export default LoadScreen;
