import Frame from "../../Frame";
import RangeCommunication from "../../RangeCommunication";
import BoxAlert from "../../BoxAlert";

const Communication1 = ({ handleNextStep }) => {
  return (
    <Frame type={3} className='center'>
      <RangeCommunication
        label='J’écoute l’interlocuteur sans l’interrompre'
        name='teuteuecoute'
        saveIn='form_step3'
      />
      <RangeCommunication
        label='Je vérifie souvent si j’ai bien compris ce que l’autre dit'
        name='teuteucomprehension'
        saveIn='form_step3'
      />
      <RangeCommunication
        label='Je vérifie souvent si l’autre a bien compris mon message'
        name='teuteuverifie'
        saveIn='form_step3'
      />
      <BoxAlert>
        Vérifiez bien que les informations rentrées sont correctes car après
        avoir cliqué sur suivant vous ne pourrez plus les modifier dans ce
        parcours.
      </BoxAlert>
    </Frame>
  );
};

export default Communication1;
