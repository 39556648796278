import React from "react";
import Frame from "../../Frame";
import Badge from "../../Badge";
import Title from "../../Title";
const FirstBadge = ({ handleSetStep }) => {
  handleSetStep(10);

  return (
    <Frame type={3} className={"center"}>
      <Badge step='1' className={"big center"}></Badge>
      <Title className={"darkBlue center"}>
        Voici <span className='green'>votre premier Badge Greadn !</span>
      </Title>
      <p>
        Cette graine grandit en vous rapprochant d’un tarif juste et
        personnalisé.{" "}
      </p>
      <p>
        <strong>
          Commencez votre parcours par votre Tarif Journalier Seuil.
        </strong>
      </p>
    </Frame>
  );
};

export default FirstBadge;
