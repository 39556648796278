import React, { useEffect, useState } from "react";
import fetchData from "../../../services/fetchData";
import Frame from "../../Frame";
import Question from "../../Question";
import Address from "../../Address";
import Input from "../../Input";
import Select from "../../Select";

const BusinessDetails = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    fetchData({
      endpoint: "/jobs",
      method: "GET",
      saveIn: "jobsKnowledge",
    }).then((response) => {
      if (response?.operation === "success") {
        setJobs(
          response.data.map((item) => {
            return { label: item.label, value: item.id };
          })
        );
      }
    });
  }, []);

  if (
    localStorage.getItem("companyData") === null ||
    localStorage.getItem("companyData") === ""
  ) {
    if (
      localStorage.getItem("siretCompany") !== null &&
      localStorage.getItem("siretCompany") !== ""
    ) {
      fetchData({
        endpoint: "/companies/siret/" + localStorage.getItem("siretCompany"),
        method: "GET",
        saveIn: "companyData",
      }).then((response) => {
        if (response?.operation === "success") {
          window.location.reload();
        }
      });
    }

    localStorage.setItem("step0", 1);
    //window.location.reload();
  }
  const companyData = JSON.parse(localStorage.getItem("companyData"));

  return (
    <Frame type={2} className='center'>
      <Question>Informations concernant votre Activité de Freelance</Question>

      <Address
        address={JSON.parse(companyData.data.address)?.address}
        postalCode={JSON.parse(companyData.data.address)?.postal_code}
        city={JSON.parse(companyData.data.address)?.city}
        saveIn='form_businessData'
        name='address'
      ></Address>
      <Question>Informations vous concernant</Question>
      <Select
        saveIn='form_businessData'
        name='gender'
        placeholder='Genre'
        options={[
          { value: "female", label: "Femme" },
          { value: "male", label: "Homme" },
        ]}
        info='Greadn ne se sert de cette information qu’à des fins statistiques.Le genre n’intervient pas dans les résultats Greadn.'
      ></Select>

      <Input
        name='surname'
        placeholder='Nom'
        className=''
        value={JSON.parse(localStorage.getItem("userData"))?.data.surname}
        saveIn='form_businessData'
      />
      <Input
        name='name'
        placeholder='Prénom'
        className=''
        value={JSON.parse(localStorage.getItem("userData"))?.data.name}
        saveIn='form_businessData'
      />
      <Input
        name='birthdate'
        placeholder='Date de Naissance'
        className=''
        type='date'
        saveIn='form_businessData'
      />

      <Question>Métier principal en freelancing</Question>
      <p>
        Le métier est un élément fondamental du TJM. Si vous en avez plusieurs,
        lancez un parcours Greadn spécifique à chacun.
      </p>

      <Select
        saveIn='form_businessData'
        name='job_id'
        placeholder='Métier'
        options={jobs}
      ></Select>
    </Frame>
  );
};

export default BusinessDetails;
