import React, { useState, useEffect, useRef } from "react";

const CalendarMonth = ({ name = null, saveIn = null }) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const isFirstRender = useRef(true);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem(saveIn));
    if (stored && stored[name]) {
      const [year, month] = stored[name].split("-");
      if (month && year) {
        setSelectedYear(parseInt(year));
        setSelectedMonth(parseInt(month) - 1);
      }
    }
  }, [name, saveIn]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (saveIn && name) {
      localStorage.setItem(
        saveIn,
        JSON.stringify({
          ...JSON.parse(localStorage.getItem(saveIn)),
          [name]: `${selectedYear}-${
            selectedMonth < 9 ? `0${selectedMonth + 1}` : selectedMonth + 1
          }-01`,
        })
      );
    }
  }, [selectedMonth, selectedYear, saveIn, name]);

  return (
    <div className='primaryBlock calendarComponent'>
      <input
        type='hidden'
        name={name}
        value={`${selectedYear}-${
          selectedMonth < 9 ? `0${selectedMonth + 1}` : selectedMonth + 1
        }-01`}
      />
      <div className='controlsContainer'>
        <span className='yearPlaceholder'>{selectedYear}</span>

        <div className='buttonContainer'>
          <button onClick={() => setSelectedYear(selectedYear - 1)}>
            &lt;
          </button>
          <button onClick={() => setSelectedYear(selectedYear + 1)}>
            &gt;
          </button>
        </div>
      </div>
      <div className='monthContainer'>
        {[
          "Jan",
          "Fev",
          "Mar",
          "Avr",
          "Mai",
          "Jui",
          "Jul",
          "Aou",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ].map((month, index) => (
          <div
            key={index}
            className={`month ${selectedMonth === index ? "selected" : ""}`}
            onClick={() => setSelectedMonth(index)}
          >
            {month}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarMonth;
