import React, { useEffect, useState } from "react";
import Frame from "../../Frame";
import QuestionSwitch from "../../QuestionSwitch";
import fetchData from "../../../services/fetchData";

const GeneralAnswers2 = () => {
  const [questions, setQuestions] = useState([]);
  const [alreadyAnswered, setAlreadyAnswered] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      const response = await fetchData({
        endpoint: "/questions",
        saveIn: "questionsKnowledge",
      });

      if (response?.operation === "success") {
        setQuestions(
          response.data.filter((question) => question.step === "step4_general2")
        );
      }
    };

    fetchQuestions();
  }, []);

  useEffect(() => {
    const fetchUsersAnswers = async () => {
      const response = await fetchData({
        endpoint: "/users_answers",
        saveInNotRead: "usersAnswers",
      });

      if (response?.operation === "success") {
        const usersAnswers =
          response.typeof === "array"
            ? response.data.filter(
                (answer) => answer.user_id === response.userId
              )
            : Object.values(response.data);

        setAlreadyAnswered(usersAnswers);
      }
    };

    fetchUsersAnswers();
  }, []);
  return (
    <Frame type={3} className='center'>
      {questions.map((question) => (
        <QuestionSwitch
          key={question.id}
          question={question.label}
          name={question.id}
          saveIn='form_step4'
          initialValue={
            alreadyAnswered?.filter(
              (answer) => answer.question_id === question.id
            )[0]?.answer_id
          }
        />
      ))}
    </Frame>
  );
};

export default GeneralAnswers2;
