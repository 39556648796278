import React, { useEffect, useState } from "react";
import LayoutPrimary from "../components/partials/LayoutPrimary";
import Title from "../components/Title";
import StepContainer from "../components/StepContainer";
import Step from "../components/Step";
import Frame from "../components/Frame";
import Button from "../components/Button";
import getCurrent from "../services/getCurrent";
import updateUserMeta from "../services/updateUserMeta";
import Notification from "../services/Notification";

const InitParcours = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    getCurrent("user").then(setUser);
  }, []);

  const handleClick = (type) => {
    updateUserMeta({ key: "type", value: type }).then((response) => {
      if (response?.operation === "success") {
        window.location.replace("/types-account");
      } else {
        Notification.error(response.errorMessage);
        console.error(response);
      }
    });
  };

  return (
    <LayoutPrimary bg='primary'>
      <StepContainer parcoursName='init' nextRoute='/step0'>
        <Step displayButton='both'>
          <div
            className='center'
            style={{
              width: "80%",
              maxWidth: "500px",
              margin: "0 auto",
              textAlign: "center",
            }}
          >
            <Title>Obtenez votre Tarif Journalier Moyen personnalisé !</Title>
            <p>
              <strong>
                Greadn détermine votre TJM juste et 100% personnalisé.
              </strong>
              Et bien plus...
            </p>
            <p>Laissez-vous guider au fil des étapes.</p>
            <p>
              <strong>
                Comptez entre 30 min et 2 H pour répondre précisément aux
                questions.
              </strong>
            </p>
            <p>
              Greadn respecte la confidentialité de vos données et met tout en
              oeuvre pour assurer leur sécurité. L’équipe Greadn vous souhaite
              un bon parcours.
            </p>
            <p style={{ color: "#40B59E" }}>
              <strong>L’quipe Greadn vous souhaite un bon parcours.</strong>
            </p>
          </div>
        </Step>
        <Step className='center' displayButton={false}>
          <Title>{user?.name}, quel est votre profil ?</Title>
          <p>
            Sélectionnez la réponse la plus juste pour commencer l’aventure
            Greadn.
          </p>
          <Frame type={2}>
            <Button
              type='square'
              className='col4 sm-col12 square-purple'
              onClick={() => handleClick("freelance")}
            >
              Vous êtes Freelance
            </Button>
            <Button
              type='square'
              className='col4 sm-col12 square-blue'
              onClick={() => handleClick("freelance-lancer")}
            >
              Vous allez vous lancer en tant que Freelance
            </Button>
            <Button
              type='square'
              className='col4 sm-col12 square-green'
              onClick={() => handleClick("entreprise-freelance")}
            >
              Votre entreprise souhaite faire appel à un freelance
            </Button>
          </Frame>
        </Step>
        <Step>{/* Next Parcours */}</Step>
      </StepContainer>
    </LayoutPrimary>
  );
};

export default InitParcours;
