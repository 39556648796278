const Loader = ({ animate = false }) => {
  return (
    <div className={"loaderComponent" + (animate ? " animate" : "")}>
      <svg
        width='79'
        height='79'
        viewBox='0 0 79 79'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g
          id='Capture_d_e&#204;&#129;cran_2022-06-02_a&#204;&#128;_11.57.30_...barbant..._Barbant_Image'
          clipPath='url(#clip0_4056_7885)'
        >
          <path
            id='Vector'
            d='M52.1707 19.012L50.3893 21.9246C52.844 23.4134 54.8606 25.319 56.4288 27.5026C58.7795 30.7763 60.1068 34.6832 60.2761 38.6843C60.4454 42.689 59.4709 46.7735 57.2105 50.4737C55.7 52.9417 53.7765 54.9751 51.5789 56.5628C48.2842 58.9425 44.3654 60.3045 40.3609 60.511C36.3546 60.7157 32.2785 59.7774 28.5982 57.5497C26.1435 56.061 24.127 54.1553 22.5587 51.9717C20.208 48.698 18.8807 44.7911 18.7114 40.79C18.5421 36.7853 19.5166 32.7008 21.777 29.0006C23.2875 26.5327 25.211 24.4992 27.4087 22.9115C30.7033 20.5318 34.6221 19.1698 38.6249 18.9651C42.6312 18.7604 46.7072 19.6987 50.3875 21.9264L52.1689 19.0138L53.9503 16.1011C50.7123 14.1368 47.2074 12.9217 43.662 12.4138C38.3402 11.6501 32.926 12.472 28.0843 14.7072C23.2445 16.9405 18.9699 20.6051 15.983 25.4893C13.9899 28.7448 12.7437 32.2606 12.2044 35.8107C11.3936 41.1394 12.1676 46.5465 14.36 51.3686C16.5506 56.1889 20.1775 60.4312 25.0354 63.375C28.2734 65.3393 31.7783 66.5544 35.3238 67.0623C40.6455 67.826 46.0597 67.0041 50.9014 64.769C55.7431 62.5338 60.0176 58.8692 63.0045 53.985C64.9976 50.7295 66.2438 47.2137 66.7831 43.6636C67.5939 38.3349 66.8199 32.9278 64.6275 28.1057C62.4369 23.2854 58.81 19.0432 53.9521 16.0994L52.1707 19.012Z'
            fill='url(#paint0_linear_4056_7885)'
          />
          <path
            id='Vector_2'
            d='M39.3725 67.3363C43.1732 67.3195 46.8152 66.5322 50.1252 65.1195C55.0928 62.9995 59.3168 59.4848 62.3105 55.0689C65.3061 50.6549 67.0697 45.3202 67.0932 39.6157C67.1015 37.7385 65.5854 36.2225 63.7083 36.2308C61.8311 36.2391 60.3016 37.7686 60.2933 39.6458C60.2805 42.5274 59.6861 45.2567 58.6243 47.7477C57.0316 51.4823 54.3763 54.6766 51.0448 56.9336C47.7115 59.1924 43.7216 60.5155 39.4026 60.5364C37.5255 60.5447 35.996 62.0742 35.9877 63.9514C35.9793 65.8286 37.4954 67.3446 39.3725 67.3363Z'
            fill='#FC7315'
          />
          <path
            id='Vector_3'
            d='M53.4324 63.4225C56.7163 61.4756 59.4648 58.9469 61.613 56.054C64.8344 51.712 66.7005 46.555 67.0482 41.2497C67.3922 35.948 66.2054 30.4785 63.3258 25.5812C62.3784 23.9682 60.2952 23.4414 58.6738 24.4032C57.0524 25.3649 56.5071 27.4529 57.4546 29.0658C58.9094 31.5402 59.7828 34.1811 60.1307 36.8546C60.6526 40.8648 59.9791 44.9498 58.2444 48.5782C56.508 52.2083 53.7281 55.3712 50.0014 57.5836C48.3801 58.5454 47.8347 60.6333 48.7822 62.2462C49.7297 63.8592 51.8128 64.386 53.4342 63.4243L53.4324 63.4225Z'
            fill='#40B59E'
          />
          <path
            id='Vector_4'
            d='M65.8421 47.8634C66.9891 44.2305 67.351 40.5244 67.0141 36.9569C66.5089 31.6002 64.4457 26.5531 61.1461 22.4163C57.8482 18.2812 53.2943 15.0547 47.8555 13.3833C46.0645 12.8338 44.1544 13.8431 43.5872 15.6373C43.0201 17.4314 44.0143 19.3345 45.8036 19.8858C48.5496 20.732 50.9747 22.0873 53.0293 23.82C56.1078 26.4191 58.3454 29.8803 59.4825 33.7114C60.6196 37.5462 60.6616 41.7384 59.3593 45.8671C58.7939 47.6631 59.7864 49.5643 61.5756 50.1156C63.3666 50.6651 65.2767 49.6558 65.8439 47.8616L65.8421 47.8634Z'
            fill='url(#paint1_linear_4056_7885)'
          />
        </g>
        <defs>
          <linearGradient
            id='paint0_linear_4056_7885'
            x1='25.0362'
            y1='63.3741'
            x2='53.7615'
            y2='15.9861'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='white' stopOpacity='0' />
            <stop offset='0.23' stop-color='#AAAAC1' stopOpacity='0.34' />
            <stop offset='0.53' stop-color='#505181' stopOpacity='0.7' />
            <stop offset='0.8' stop-color='#1A1B59' stopOpacity='0.92' />
            <stop offset='1' stop-color='#07084C' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_4056_7885'
            x1='50.0361'
            y1='46.5695'
            x2='59.1723'
            y2='16.8671'
            gradientUnits='userSpaceOnUse'
          >
            <stop stop-color='#5042A6' stopOpacity='0.9' />
            <stop offset='1' stop-color='#07084C' />
          </linearGradient>
          <clipPath id='clip0_4056_7885'>
            <rect
              width='55.4522'
              height='54.9635'
              fill='white'
              transform='translate(78.5322 39.5635) rotate(135)'
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Loader;
