import React, { useState } from "react";

const Checkbox = ({ label, onChange }) => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    const newValue = !checked;
    setChecked(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <div className='checkboxComponent' onClick={handleCheckboxChange}>
      <div className={`checkbox ${checked ? "checked" : ""}`}>
        <div className='innerCheck'></div>
      </div>
      <div className='label'>{label}</div>
    </div>
  );
};

export default Checkbox;
